<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useTransmittalsStore } from '~/dms/store/transmittals.store.js';

const props = defineProps({
  store_key: {
    type: String,
  },
  transmittals: {
    type: Array,
    default: () => ([]),
  },
});

const emit = defineEmits(['loadMore', 'tableInstanceCreated']);
const { $t } = useCommonImports();
const router = useRouter();
const route = useRoute();
const active_tab = inject('active_transmittal_tab');
const transmittals_store = useTransmittalsStore(props?.store_key);
const custom_classes = 'group-hover:!bg-gray-50';
const table_columns = [
  {
    header: $t('Name'),
    accessorKey: 'name',
    id: 'name',
    custom_classes,
  },
  {
    header: $t('Status'),
    accessorKey: 'status',
    id: 'status',
    custom_classes,
  },
  {
    header: $t('Created on'),
    accessorKey: 'created_at',
    id: 'created_at',
    custom_classes,
  },
  {
    header: $t('Issue purpose'),
    accessorKey: 'issue_purpose',
    id: 'issue_purpose',
    custom_classes,
  },
  {
    header: $t('Category'),
    accessorKey: 'category',
    id: 'category',
    custom_classes,
  },
  {
    header: $t('Progress'),
    accessorKey: 'total',
    id: 'total',
    size: 200,
    custom_classes,
  },
  {
    header: '',
    accessorKey: 'latest_message_timestamp',
    id: 'latest_message_timestamp',
    custom_classes,
  },
];
const transmittals = computed(() => {
  if (props.transmittals?.length) {
    return props.transmittals.map((transmittal) => {
      const status = getStatus(transmittal);
      return {
        ...transmittal,
        status_label: status?.label,
        status_color: status?.color,
      };
    });
  }

  return [];
});

function getStatus(transmittal) {
  return transmittals_store.transmittal_status(transmittal);
}

function rowClicked(transmittal) {
  const { uid, published, cancelled } = transmittal;
  if (!published && active_tab.value === 'unpublished' && !cancelled)
    router.push({ name: 'files-create-transmittals', query: { transmittal: uid } });
  else
    router.push({ name: 'files-transmittals-detail', params: { asset_id: route.params.asset_id, transmittal_uid: uid }, query: transmittal.status === 'Review Completed' ? { submitted_by_me: true } : undefined });
}

function formatTable(cell) {
  if (cell.row.original?.unread) {
    return {
      backgroundColor: 'white',
    };
  }
  else {
    return {
      backgroundColor: '#FCFCFD',
    };
  }
}

defineExpose({
  columns: table_columns,
});
</script>

<template>
  <div>
    <div v-if="!transmittals?.length">
      <HawkIllustrations type="no-data" />
    </div>
    <template v-else>
      <div id="transmittal-list" class="my-4 h-[calc(100vh_-_275px)] scrollbar">
        <HawkTable
          parent_id_for_infinite_scrolling="transmittal-list"
          :pagination_config="{ totalRows: transmittals_store.total_count, pageSize: 25 }"
          is_gapless
          :data="transmittals"
          :columns="table_columns"
          :sticky_group_label="true"
          :enable_infinite_scroll="true"
          :format-table="formatTable"
          @load-more="emit('loadMore', $event)"
          @row-clicked="rowClicked"
          @table-instance-created="emit('tableInstanceCreated', $event)"
        >
          <template #name="{ data: { row: { original } } }">
            <div class="text-gray-900  text-sm">
              <div class="text-xs text-gray-600 font-medium">
                {{ original.number }}
              </div>
              <div :class="original.unread ? 'font-semibold' : ''">
                {{ original.name }}
              </div>
            </div>
          </template>
          <template #status="{ data: { row: { original: { status, status_color, status_label } } } }">
            <hawk-badge
              v-if="status"
              :color="status_color"
            >
              {{ status_label }}
            </hawk-badge>
            <span v-else>-</span>
          </template>
          <template #category="{ data: { row: { original: { category } } } }">
            <div class="text-gray-700 font-medium text-xs">
              <hawk-category-name :uid="category" />
            </div>
          </template>
          <template #priority="{ data: { row: { original: { priority } } } }">
            <TaskPriority :priority="transmittals_store.priority_value(priority)" />
          </template>
          <template #created_at="{ data: { row: { original: { created_at } } } }">
            <div class="text-gray-600 text-sm">
              {{ $date(created_at, 'DATE_MED') }}
            </div>
          </template>
          <template #latest_message_timestamp="{ data: { row: { original } } }">
            <div class=" text-sm" :class="original.unread ? 'text-gray-900 font-medium' : 'text-gray-600'">
              {{ original.latest_message_timestamp ? $date(original.latest_message_timestamp, 'DATE_MED') : "" }}
            </div>
          </template>
          <template #total="{ data: { row: { original: { total, completed } } } }">
            <hawk-progress
              class="flex-auto"
              :progress="total ? (completed / total) * 100 : 0"
            >
              <div>
                {{ completed }}/{{ total }}
              </div>
            </hawk-progress>
          </template>
        </HawkTable>
      </div>
    </template>
  </div>
</template>
