<script setup>
import { useRoute } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';

import HawkDateRangeFilter from '~/common/components/organisms/hawk-filters/hawk-date-range-filter.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

import DashboardCreateStandardMaps from '~/dashboard/components/create-dashboard/dashboard-create-standard-maps.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

const props = defineProps({
  isEditing: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['close', 'save']);

const $toast = inject('$toast');

const route = useRoute();
const authStore = useAuthStore();
const dashboard_store = useDashboardStore();
const { $t } = useCommonImports();

const { DASHBOARD_GLOBAL_DATE_FILTERS } = useFamConstants($t);

const form$ = ref(null);
const date_filter = ref(DASHBOARD_GLOBAL_DATE_FILTERS[0]);

const can_share_reports = computed(() => {
  if (['global', 'organization', 'forms', 'inventory'].includes(dashboard_store.scope))
    return authStore.check_permission('share_reports', route.params.asset_id);
  else return false;
});
async function onCreate() {
  const form_data = form$?.value?.data;
  if (!form_data) {
    $toast({
      title: $t('Something went wrong'),
      text: $t('Please try again'),
      type: 'error',
    });
    return emit('close');
  }
  let references;
  if (route.name === 'pm-dashboard') {
    references = [
      {
        resource_id: route.params.schedule_id,
        resource_type: 'project_management',
      },
    ];
  }
  else if (route.name === 'form-template-detail-report') {
    references = [
      {
        resource_id: route.params.form_id,
        resource_type: 'forms',
      },
    ];
  }
  else {
    references = [];
  }

  const sharing_data = {
    ...(form_data.assignees_input.assignees_type === 'Everyone'
      ? { public: true, members: {} }
      : {
          members: form_data.assignees_input.assignees.reduce((acc, member) => {
            acc[member.uid] = 'read';
            return acc;
          }, {}),
          public: false,
        }
    ),
  };

  let body;
  if (form_data.configuration_option === 'standard') {
    body = {
      name: form_data.dashboard_name,
      report_data: {
        module: form_data.module,
        data: {
          id: form_data.map,
        },
      },
      default: false,
      standard: true,
      organization: authStore.current_organization?.uid,
      ...(dashboard_store.scope !== 'organization'
        ? {
            asset: dashboard_store.widget_asset,
          }
        : {}),
      references,
      ...sharing_data,
    };
  }

  else {
    body = {
      name: form_data.dashboard_name,
      report_data: props.isEditing ? dashboard_store.current_dashboard.report_data : {},
      default: false,
      organization: authStore.current_organization?.uid,
      ...(dashboard_store.scope !== 'organization'
        ? {
            asset: dashboard_store.widget_asset,
          }
        : {}),
      references,
      ...sharing_data,
      selected_date_range: form_data.default_time_range.default_time_range_enabled ? date_filter.value : {},
    };
  }

  try {
    await dashboard_store.update_dashboard({ ...body, ...(props.isEditing ? { uid: dashboard_store.current_dashboard.uid } : {}) }, $t);
    emit('close');
  }
  catch {
    emit('close');
  }
}

function onFormMounted(el$) {
  if (props.isEditing) {
    date_filter.value = dashboard_store.current_dashboard.selected_date_range?.value ? dashboard_store.current_dashboard.selected_date_range : DASHBOARD_GLOBAL_DATE_FILTERS[0];
    el$.update({
      dashboard_name: dashboard_store.current_dashboard.name,
      assignees_input: {
        assignees_type: dashboard_store.current_dashboard.public ? 'Everyone' : 'Custom',
        assignees: Object.keys(dashboard_store.current_dashboard.members),
      },
      configuration_option: dashboard_store.current_dashboard.standard === true ? 'standard' : 'custom',
      default_time_range: {
        default_time_range_enabled: !!dashboard_store.current_dashboard.selected_date_range?.value,
      },
    });
  }
}
</script>

<template>
  <HawkModalTemplate content_class="rounded-lg" @close="emit('close')">
    <template #header_left>
      <div class="flex flex-col justify-start">
        {{ isEditing ? $t('Edit Dashboard') : $t("New Dashboard") }}
      </div>
    </template>
    <template #default>
      <Vueform
        ref="form$"
        size="sm"
        class="w-[600px]"
        :columns="{
          default: { container: 12, label: 4, wrapper: 12 },
          sm: { container: 12, label: 4, wrapper: 12 },
          md: { container: 12, label: 4, wrapper: 12 },
        }"
        @mounted="onFormMounted"
      >
        <TextElement
          name="dashboard_name"
          class="w-full"
          :label="$t('Name')"
          :placeholder="$t('Enter name')"
        />
        <RadiogroupElement
          v-if=" ['global'].includes(dashboard_store.scope)"
          class="mt-4"
          default="custom"
          :label="$t('Type')"
          name="configuration_option"
          :disabled="isEditing"
          :items="[
            {
              value: 'custom',
              label: $t('Custom'),
              description: $t('Custom'),
            },
            {
              value: 'standard',
              label: $t('Standard'),
              description:
                $t('Standard'),
            },
          ]"
        />
        <SelectElement
          :conditions="[['configuration_option', '==', 'standard']]"
          name="module"
          default="maps"
          disabled
          :can-deselect="false"
          :native="false"
          :search="true"
          :label="$t('Module')"
          :items="[{
            label: $t('maps'),
            value: 'maps',
          }]"
          class="my-4"
        />
        <ObjectElement
          v-if="can_share_reports"
          class="my-4"
          name="assignees_input"
          :label="$t('Share with')"
        >
          <RadiogroupElement
            name="assignees_type"
            :items="{
              Everyone: $t('Everyone'),
              Custom: $t('Custom'),
            }"
            default="Custom"
          />
          <hawk-assignee-input
            :options="{
              name: 'assignees',
              conditions: [
                ['assignees_input.assignees_type', 'Custom'],
              ],
              has_teams: true,
              placeholder: $t('Select members'),
              columns: {
                default: { container: 12, label: 12, wrapper: 12 },
                sm: { container: 12, label: 12, wrapper: 12 },
                md: { container: 12, label: 12, wrapper: 12 },
              },
            }"
            :multi="true"
          />
        </ObjectElement>
        <DashboardCreateStandardMaps :conditions="[['module', '==', 'maps']]" />
        <ObjectElement
          name="default_time_range"
          :label="$t('Default timerange')"
        >
          <ToggleElement
            class="mb-2"
            name="default_time_range_enabled"
          />
          <StaticElement
            name="time_range"
            :conditions="[['default_time_range.default_time_range_enabled', '==', true]]"
          >
            <template #default>
              <HawkDateRangeFilter
                class="w-full"
                width-class="w-full"
                position="top"
                :active-range="date_filter"
                :date-filters="DASHBOARD_GLOBAL_DATE_FILTERS"
                @filter-applied="e => date_filter = e"
              />
            </template>
          </StaticElement>
        </ObjectElement>
      </Vueform>
    </template>
    <template #footer>
      <Vueform size="sm">
        <div class="flex justify-end w-full col-span-full">
          <ButtonElement
            name="cancel"
            class="mr-4"
            :secondary="true"
            @click="emit('close')"
          >
            {{ $t('Cancel') }}
          </ButtonElement>
          <ButtonElement
            name="save"
            @click="onCreate()"
          >
            {{ $t('Save') }}
          </ButtonElement>
        </div>
      </Vueform>
    </template>
  </HawkModalTemplate>
</template>
