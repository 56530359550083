<script setup>
import { isEqual } from 'lodash-es';
import { watch } from 'vue';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import { useCommonStore } from '~/common/stores/common.store.js';

import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useDashboardTerraStore } from '~/dashboard/store/dashboard-terra.store.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  // eslint-disable-next-line vue/prop-name-casing
  content_height: {
    type: Number,
  },
  activeSelectedRange: {
    type: Array,
    default: () => [],
  },
});

const common_store = useCommonStore();

const $services = inject('$services');
const $date = inject('$date');

const dashboard_selected_range = inject('dashboardSelectedRange');

const loading = ref(false);
const table_dataset = ref([]);
const table_columns = ref([]);
const graph_data = ref(null);
const payload = ref(null);
const no_data = ref(false);
const prevent_watcher = ref(false);
const dashboard_terra_store = useDashboardTerraStore();
const dashboard_store = useDashboardStore();

function getUserFullName(user) {
  // TODO: replace with global function once main is merged to pre-prod
  let user_name = '';

  if (user?.first_name && !user?.last_name)
    user_name = user.first_name;
  else if (user?.first_name && user?.last_name)
    user_name = `${user.first_name} ${user.last_name || ''}`;
  else
    user_name = user?.email || '';

  return user_name;
}

async function getReports() {
  graph_data.value = null;
  loading.value = true;
  payload.value = dashboard_terra_store.parse_terra_form_to_server_format(props.data.data);
  try {
    const { data } = await $services.terra_view_service.get_graph({ body: payload.value });
    if (data.data?.length) {
      if (props.data?.data?.group?.key !== 'none')
        generateNestedColumns(data.data);
      else
        generateColumns(data.data);
      generateDataset(data.data);
      no_data.value = false;
      loading.value = false;
    }
    else {
      no_data.value = true;
      table_dataset.value = [];
      table_columns.value = [];
      loading.value = false;
    }
  }
  catch {
    loading.value = false;
  }
}

const label_map = {
  date: 'Date',
  interval: 'Interval',
  project: 'Project',
  group: 'Group',
  field: 'Field quantity',
  user: 'User',
  category: 'Category',
};

const months_order = {
  jan: 1,
  feb: 2,
  mar: 3,
  apr: 4,
  may: 5,
  jun: 6,
  jul: 7,
  aug: 8,
  sep: 9,
  oct: 10,
  nov: 11,
  dev: 12,
};

const force_render = ref(0);

// universal function that can be used to sort an array based on a provided key
// date types in descending order, others in ascending order
// key is the column, row, or group key that shows us how to sort, accessorkey is how you get the data from the obj
function sortArrayData(arr = [], key = null, accessorKey = 'header') {
  if (!key)
    return [];

  if (arr.length === 1)
    return arr;

  let res = [];

  // edge case when column is date, and interval is set to monthly
  if (
    key === 'interval'
    && props.data.data.timerange_interval === 'monthly'
  ) {
    res = arr.sort((a, b) => {
      const a_label = a[accessorKey];
      const b_label = b[accessorKey];

      const a_month_index
              = months_order[a_label?.toLowerCase().slice(0, 3) || 0];
      const b_month_index
              = months_order[b_label?.toLowerCase().slice(0, 3) || 0];
      a = `${a_label?.slice(4)}-${a_month_index}`;
      b = `${b_label?.slice(4)}-${b_month_index}`;
      const b_greater_than_a = a < b ? 1 : 0;
      return a > b ? -1 : b_greater_than_a;
    });
  }

  else if (['date', 'interval'].includes(key)) {
    res = arr.sort((a, b) => {
      const a_label = a[accessorKey];
      const b_label = b[accessorKey];

      a = a_label
        .split(/[_-]/)
        .reverse()
        .join('');
      b = b_label
        .split(/[_-]/)
        .reverse()
        .join('');
      const b_greater_than_a = a < b ? 1 : 0;
      return a > b ? -1 : b_greater_than_a;
    });
  }
  else {
    res = arr.sort((a, b) => {
      const a_label = a[accessorKey].toLowerCase();
      const b_label = b[accessorKey].toLowerCase();

      return a_label?.localeCompare(b_label) || 0;
    });
  }

  return res;
}

function formatRowDates(arr) {
  const res = [];
  arr.forEach((d) => {
    const date = d.date
      .split('-')
      .reverse()
      .join('-');
    d.date = $date(date, 'DATE_MED');
    res.push(d);
  });
  return res;
}

function formatColumnDates(arr) {
  const res = [];
  if (props.data.data.timerange_interval === 'daily') {
    arr.forEach((column) => {
      let date = column.header
        .split('-')
        .reverse()
        .join('-');
      date = `20${date}`;
      column.header = $date(date, 'DATE_MED');
      res.push(column);
    });
  }
  else if (props.data.data.timerange_interval === 'weekly') {
    arr.forEach((column) => {
      let date_from = column.header.split(' to ')[0]
        .split('-')
        .reverse()
        .join('-');
      date_from = `20${date_from}`;

      date_from = $date(date_from, 'DATE_MED');

      let date_to = column.header.split(' to ')[1]
        .split('-')
        .reverse()
        .join('-');
      date_to = `20${date_to}`;

      date_to = $date(date_to, 'DATE_MED');

      column.header = `${date_from} to ${date_to}`;
      res.push(column);
    });
  }
  else {
    return arr;
  }
  return res;
}

function formatGroupDates(arr) {
  const res = [];
  arr.forEach((column) => {
    if (column.header !== '') {
      const date = column.header
        .split('-')
        .reverse()
        .join('-');
      column.header = $date(date, 'DATE_MED');
      res.push(column);
    }
    else { res.push(column); }
  });
  return res;
}

function getUserName(uid = null) {
  if (!uid)
    return 'Unknown';

  const organization_user = common_store.get_user(uid);
  const internal_user = common_store.internal_users_map?.[uid];

  return getUserFullName(organization_user || internal_user || null);
}

function getCategoryName(uid = null) {
  if (!uid)
    return 'N/A';

  const category = common_store.get_category(uid);
  return category.name;
};

const columns_widths_map = computed(() => props.data.data.columns_widths || {});

function generateColumns(data) {
  const columns = [];

  const row_key = props.data.data.row.key;
  const column_key = props.data.data.column.key === 'date' ? 'interval' : props.data.data.column.key;

  // the key that will be used as a row label, is already added
  const appended_columns = [row_key];

  const first_column = {
    accessorKey: row_key,
    id: row_key,
    header: label_map[row_key],
    size: columns_widths_map.value[row_key] || 400,
  };

  // key that gets the column label
  const column_label_key = ['date', 'interval', 'user', 'category'].includes(column_key) ? column_key : `${column_key}_name`;
  data.forEach((item) => {
    let column_id = item[column_key];
    if (!column_id)
      column_id = `undefined-${item[row_key]}`;

    if (appended_columns.includes(column_id))
      return;

    appended_columns.push(column_id);

    // if column is field, append units
    let column_label = item[column_label_key];
    if (column_key === 'field')
      column_label = `${column_label} (${item.units})`;
    else if (column_key === 'user')
      column_label = getUserName(column_label);
    else if (column_key === 'category')
      column_label = getCategoryName(column_label);

    columns.push({
      id: column_id,
      accessorKey: column_id,
      header: column_label,
      size: columns_widths_map.value[column_id] || 150,
    });
  });

  let sorted_columns = sortArrayData(Object.values(columns || {}), column_label_key, 'header');
  if (['date', 'interval'].includes(column_key))
    sorted_columns = formatColumnDates(sorted_columns);

  table_columns.value = [
    first_column,
    ...sorted_columns,
  ];
}

function generateNestedColumns(data) {
  const columns = {};
  const row_key = props.data.data.row.key;
  const column_key = props.data.data.column.key === 'date' ? 'interval' : props.data.data.column.key;
  const group_key = props.data.data.group.key;

  const appended_parent_columns = [row_key];

  const first_column = {
    accessorKey: row_key,
    id: row_key,
    header: label_map[row_key],
    size: columns_widths_map.value[row_key] || 400,
  };

  // first add the parent columns
  data.forEach((item) => {
    let parent_column_id = item[group_key];
    if (!parent_column_id)
      parent_column_id = `undefined-parent-${item[column_key]}`;

    if (appended_parent_columns.includes(parent_column_id))
      return;

    appended_parent_columns.push(parent_column_id);

    const parent_label_key = ['date', 'interval', 'user', 'category'].includes(group_key) ? group_key : `${group_key}_name`;

    let parent_label = item[parent_label_key];
    if (group_key === 'field')
      parent_label = `${parent_label} (${item.units})`;
    else if (group_key === 'user')
      parent_label = getUserName(parent_label);
    else if (group_key === 'category')
      parent_label = getCategoryName(parent_label);

    columns[parent_column_id] = {
      id: parent_column_id,
      header: parent_label,
      columns: [],
      size: columns_widths_map.value[parent_column_id] || 150,
    };
  });

  // add the sub columns
  data.forEach((item) => {
    let parent_column_id = item[group_key];
    if (!parent_column_id)
      parent_column_id = `undefined-parent-${item[column_key]}`;
    const parent_column = columns[parent_column_id];
    let column_id = item[column_key];
    if (!column_id)
      column_id = `undefined-${item[row_key]}`;

    const child_cols_map = parent_column.columns?.map(c => c.id);
    if (child_cols_map.includes(column_id))
      return;

    const column_label_key = ['date', 'interval', 'user', 'category'].includes(column_key) ? column_key : `${column_key}_name`;
    let column_label = item[column_label_key];
    if (column_key === 'field')
      column_label = `${column_label} (${item.units})`;
    else if (column_key === 'user')
      column_label = getUserName(column_label);
    else if (column_key === 'category')
      column_label = getCategoryName(column_label);

    const col_id = `${parent_column_id}-${column_id}`;
    columns[parent_column_id].columns.push({
      id: col_id,
      accessorKey: col_id,
      header: column_label,
      size: columns_widths_map.value[col_id] || 150,
    });
  });

  // sort group columns and nested columns
  let sorted_parent_columns = sortArrayData(Object.values(columns || {}), group_key, 'header');

  sorted_parent_columns.forEach((parent) => {
    parent.columns = sortArrayData(parent.columns, column_key, 'header');
  });

  // if group or column is date, humanize
  if (['date', 'interval'].includes(group_key)) {
    sorted_parent_columns = formatGroupDates(sorted_parent_columns);
  }
  else if (['date', 'interval'].includes(column_key)) {
    sorted_parent_columns.forEach((parent) => {
      parent.columns = formatColumnDates(parent.columns);
    });
  }

  table_columns.value = [
    first_column,
    ...sorted_parent_columns,
  ];
}

function generateDataset(data) {
  const dataset = {};

  const row_key = props.data.data.row.key;
  const column_key = props.data.data.column.key === 'date' ? 'interval' : props.data.data.column.key;
  const group_key = props.data.data.group.key === 'none' ? null : props.data.data.group.key;

  // make data rows
  data.forEach((item) => {
    const row_id = item[row_key];
    let col_id = item[column_key];
    if (!col_id)
      col_id = `undefined-${item[row_key]}`;
    let group_id = item[group_key];
    if (!group_id)
      group_id = `undefined-parent-${col_id}`;
    let accessorKey = null;
    if (group_key)
      accessorKey = `${group_id}-${col_id}`;
    else
      accessorKey = col_id;

    // when iterating, sometimes we get to the same row id, so to avoid appending it twice, we check if it exits
    // if it exists, we just add new data
    if (!dataset[row_id]) {
      dataset[row_id] = { id: row_id };
      // key that gets the row label
      const row_label_key = ['date', 'user', 'category'].includes(row_key) ? row_key : `${row_key}_name`;
      let row_label = item[row_label_key];
      if (row_key === 'field' && item.units)
        row_label = `${row_label} (${item.units})`;
      else if (row_key === 'user')
        row_label = getUserName(row_label);
      else if (row_key === 'category')
        row_label = getCategoryName(row_label);

      dataset[row_id][row_key] = row_label;
    }

    dataset[row_id][accessorKey] = item.value;
  });

  let sorted_data = sortArrayData(Object.values(dataset || {}), row_key, row_key);
  if (row_key === 'date')
    sorted_data = formatRowDates(sorted_data);

  table_dataset.value = sorted_data;

  force_render.value += 1;
}
const height = computed(() => {
  return ((props.data.h || 22) * 20) - 44;
});

function columnResized(_resized_column, columns_widths) {
  // prevents the table from rerendering
  prevent_watcher.value = true;
  dashboard_store.set_table_column_widths(
    props?.id,
    columns_widths,
  );
}

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: props.data.data.type,
    renderAt: `chart-container-${props?.id}`,
    renderType: 'table',
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: props.data.data.name,
    dashboard_selected_range,
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      columns: table_columns.value,
      activities: table_dataset.value,
      dataset: table_dataset.value,
      is_transpose: false,
      dashboard_index: props.data.i,
      is_new_pivot_chart: props.data.data.chart === 'workflow_pivot_table',
    },
  });
  dashboard_store.update_new_print_map((props.data?.data?.name || 'untitled'), {
    type: 'table',
    widget_type: props.data?.data?.type,
    data: table_dataset.value.map(a => Object.values(a)),
  });
}

watch(() => props.data.data, async (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val)) {
    if (prevent_watcher.value) {
      prevent_watcher.value = false;
      return;
    }
    await getReports();
    if (props?.id !== 'preview')
      updatePrintMap();
  }
}, { immediate: true }, { deep: true });

watch(() => props.activeSelectedRange, async (new_val, old_val) => {
  if (!isEqual(new_val, old_val) && (props?.id !== 'preview')) {
    await getReports();
    updatePrintMap();
  }
});
</script>

<template>
  <div>
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <div v-if="no_data" class="text-sm font-semiBold w-full" :class="dashboard_store.is_mobile_view ? 'h-[240px] grid place-items-center' : 'mt-8 flex justify-center'">
      {{ $t('No data present') }}
    </div>
    <hawk-loader v-if="loading" />
    <a v-else-if="table_dataset?.length && table_columns?.length">
      <div class="w-full scrollbar" :style="{ height: `${content_height || height}px` }">
        <HawkTable
          :key="force_render"
          :data="table_dataset"
          :columns="table_columns"
          additional_table_classes=""
          freeze_column_id="name"
          additional_row_classes="even:bg-gray-50"
          :container_class="`h-[${content_height || height}px]`"
          :disable_resize="!dashboard_store.is_editing_dashboard"
          :show_menu_header="false"
          :show_column_borders="true"
          :striped="true"
          is_gapless
          cell_height="30px"
          @column-resized="columnResized"
        />
      </div>
    </a>
  </div>
</template>
