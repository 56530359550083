<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { groupBy, omit } from 'lodash-es';
import { computed, inject } from 'vue';
import { useModal } from 'vue-final-modal';
import { useRouter } from 'vue-router';

import { useAuthStore } from '~/auth/stores/auth.store';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import HawkShare from '~/common/components/organisms/hawk-share.vue';
import useEmitter from '~/common/composables/useEmitter';

import DmsDocumentsRequestForm from '~/dms/components/documents/forms/dms-documents-request-form.vue';
import FilesTransmittalEditForm from '~/dms/components/transmittals/forms/files-transmittals-edit-form.vue';
import FilesTransmittalRemindersForm from '~/dms/components/transmittals/forms/files-transmittals-reminders-form.vue';

import TransmittalDetailMembersInfo from '~/dms/components/transmittals/transmittal-detail/transmittal-detail-members-info.vue';
import TransmittalDetailWorkflow from '~/dms/components/transmittals/transmittal-detail/transmittal-detail-workflow.vue';
import { useTransmittalPermissions } from '~/dms/composables/transmittal-permissions.composable';
import { useTransmittalsStore } from '~/dms/store/transmittals.store';

const props = defineProps({
  transmittal: {
    type: Object,
  },
  is_detail_page: {
    type: Boolean,
    default: false,
  },
  read_only: {
    type: Boolean,
    default: false,
  },
  is_public_page: {
    type: Boolean,
    default: false,
  },
});

const document_request_modal = useModal({
  component: DmsDocumentsRequestForm,
  attrs: {
    onClosed() {
      const attrs = omit(document_request_modal.options.attrs, ['is_cancel', 'content', 'header', 'sub_header', 'header_icon', 'button_text', 'is_mark_as_submitted']); // reset attrs
      document_request_modal.options.attrs = attrs;
    },
  },
});

const transmittal_workflow_modal = useModal({
  component: TransmittalDetailWorkflow,
  attrs: {
    transmittal: props.transmittal,
  },
});

const $t = inject('$t');
const emitter = useEmitter();

const transmittals_store = useTransmittalsStore();
const auth_store = useAuthStore();

const router = useRouter();

const logged_in_user = computed(() => auth_store.logged_in_user_details);
const transmittal_permissions = useTransmittalPermissions();

const is_cancelled = !!props.transmittal?.cancelled;
const is_completed = !!props.transmittal?.completed;
const all_members = computed(() => {
  return (props.transmittal?.members || []).map(member => ({ ...member, access: member.role, disabled: member.role !== 'information' && (is_completed || is_cancelled) }));
});
const transmittal_status = computed(() => {
  if (props.transmittal?.uid)
    return transmittals_store.transmittal_status(props.transmittal);
  return null;
});
const can_share_transmittal = computed(() => {
  if (props.is_detail_page)
    return props.transmittal?.actions?.can_share && !props.is_public_page;
  return true;
});
const members_info = computed(() => {
  const grouped_members = groupBy(all_members.value, 'role');
  grouped_members.owner = props.is_detail_page ? props.transmittal.owner : logged_in_user.value.user_id;
  return grouped_members;
});

const is_pdf_exporting = ref(false);

const all_archive_items = (props.transmittal.status === 'completed' || props.transmittal.status === 'cancelled')
  ? [{
      label: $t('Archive'),
      uid: 'archive_transmittal',
      on_click: () => itemArchive({ is_archived: false }),
      enabled: props.transmittal?.actions?.can_archive,
    }, {
      label: $t('Unarchive'),
      uid: 'archive_transmittal',
      on_click: () => itemArchive({ is_archived: true }),
      enabled: props.transmittal?.actions?.can_unarchive,
    }]
  : [];

const archive_items = all_archive_items.filter(item => item.enabled);

const dropdown_items = computed(() => {
  const options = [
    {
      label: $t('Edit transmittal'),
      uid: 'edit_transmittal',
      on_click: openTransmittalEditModal,
      disabled: !props.transmittal?.actions?.can_modify,
    },
    {
      label: $t('Cancel transmittal'),
      uid: 'cancel_request',
      on_click: openCancelForm,
      disabled: !props.transmittal?.actions?.can_cancel,
    },
    {
      label: $t('Export pdf'),
      uid: 'cancel_request',
      on_click: () => is_pdf_exporting.value = true,
    },
    {
      label: $t('Reminders'),
      uid: 'reminders',
      on_click: openTransmittalRemindersModal,
      disabled: !props.transmittal?.actions?.can_create_reminders,
    },
    {
      label: $t('View settings'),
      uid: 'settings',
      disabled: props?.transmittal?.user_role !== 'approver',
      on_click: () => router.push({ name: 'transmittal-detail-settings', params: { transmittal_uid: props.transmittal.uid } }),
    },
    ...archive_items,
  ];
  return options;
});

const $services = inject('$services');

async function exportPDF() {
  const { data: resourceURL } = await $services.dms_transmittals.post({
    attribute: 'reports/export-pdf',
    body: { transmittalUids: [props.transmittal?.uid] },
  });
  if (resourceURL) {
    const link = document.createElement('a');
    link.href = resourceURL;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

function openCancelForm(is_cancel = true) {
  document_request_modal.patchOptions({
    attrs: {
      is_cancel,
      header_icon: IconHawkAlertTriangleRedRipple,
      header: $t('Cancel Request'),
      button_text: $t('Cancel transmittal'),
      content: $t('Are you sure you want to close this request? If yes, please add your reason for request cancellation.'),
      confirm: async (data) => {
        await transmittals_store.cancel_transmittal({
          transmittal_uid: props.transmittal?.uid,
          payload: { transmittals: { message: data.comment } },
        });
        document_request_modal.close();
      },
      onClose() {
        document_request_modal.close();
      },
    },
  });
  document_request_modal.open();
}

const transmittals_reminders_modal = useModal({
  component: FilesTransmittalRemindersForm,
});

const transmittal_edit_modal = useModal({
  component: FilesTransmittalEditForm,
});
const share_modal = useModal({
  component: HawkShare,
});

function openShareModal() {
  if (!props.is_detail_page)
    return;

  if (!can_share_transmittal.value)
    return;

  const type = props.transmittal?.type;
  const review_type = props.transmittal?.review_type;
  const is_cancelled = !!props.transmittal?.cancelled;
  const is_completed = !!props.transmittal?.completed;

  const has_submitters = type !== 'request_for_review';
  const has_reviewers = review_type !== 'workflow';
  const has_approvers = review_type !== 'workflow';

  let access_levels = [];
  if (has_submitters)
    access_levels.push({ name: 'submitter', label: $t ('Submitter'), disabled: (is_cancelled || is_completed) });
  if (has_reviewers)
    access_levels.push({ name: 'reviewer', label: $t ('Reviewer'), disabled: (is_cancelled || is_completed) });
  if (has_approvers)
    access_levels.push({ name: 'approver', label: $t('Approver'), disabled: (is_cancelled || is_completed) });
  access_levels.push({ name: 'information', label: $t('CC'), disabled: false });

  access_levels = access_levels.sort((a, b) => {
    const sorting = a.disabled ? 1 : -1;
    return a.disabled === b.disabled ? 0 : sorting;
  });

  const external_user_access_levels = ref(has_submitters ? [{ name: 'submitter', label: $t ('Submitter'), disabled: (is_cancelled || is_completed) }] : []);

  share_modal.patchOptions({
    attrs: {
      access_levels,
      external_user_access_levels,
      title: $t('Add/Edit members'),
      description: '',
      has_icon: false,
      onClose() {
        share_modal.close();
      },
      async onInput(data) {
        const members = groupBy((data?.users || []), 'access');
        const assignees = members?.submitter || [];
        const approvers = members?.approver || [];
        const reviewers = members?.reviewer || [];
        const information = members?.information || [];
        if (props.is_detail_page) {
          await transmittals_store.update_transmittal({
            transmittal_uid: props.transmittal.uid,
            transmittals: {
              members: [
                ...assignees.map(({ uid, email }) => ({ uid, email, role: 'submitter' })),
                ...approvers.map(({ uid }) => ({ uid, role: 'approver' })),
                ...reviewers.map(({ uid }) => ({ uid, role: 'reviewer' })),
                ...information.map(({ uid }) => ({ uid, role: 'information' })),
              ],
            },
          });
        }
        else {
          emitter.emit('update-transmittal-form', {
            assignees,
            approvers,
            reviewers,
            information,
          });
        }
      },
      is_modal: true,
      members: all_members.value,
    },
  });
  share_modal.open();
}

function openTransmittalEditModal() {
  transmittal_edit_modal.patchOptions({
    attrs: {
      onClose() {
        transmittal_edit_modal.close();
      },
    },
  });
  transmittal_edit_modal.open();
}

function openTransmittalRemindersModal() {
  transmittals_reminders_modal.patchOptions({
    attrs: {
      transmittal: props.transmittal,
      onClose() {
        transmittals_reminders_modal.close();
      },
    },
  });
  transmittals_reminders_modal.open();
}

function go_back() {
  router.push({ name: 'files-transmittals' });
}

const archive_warning_modal = useModal({
  component: HawkDeletePopup,
});

async function archiveHandler({ is_archived = false }) {
  if (is_archived) {
    await transmittals_store.unarchive_transmittal({
      transmittal_uid: props.transmittal.uid,
    });
  }
  else {
    await transmittals_store.archive_transmittal({
      transmittal_uid: props.transmittal.uid,
    });
  }
  router.push({ name: 'files-transmittals', query: { active: is_archived ? 'all' : 'archive' } });
}

async function itemArchive({ is_archived = false }) {
  archive_warning_modal.patchOptions({
    attrs: {
      onClose() {
        archive_warning_modal.close();
      },
      header_icon: IconHawkDashboardPreviewWarning,
      header: is_archived ? $t('Unarchive Transmittal') : $t('Archive Transmittal'),
      content: is_archived ? $t('Are you sure you want to unarchive the Transmittal? It will be moved to the regular section and will be visible.') : $t('Are you sure you want to archive the Transmittal? It will be moved to archived section and will be hidden.'),
      button_text: $t('Proceed'),
      button_color: 'primary',
      confirm: async () => {
        await archiveHandler({ is_archived });
      },
    },
  });
  archive_warning_modal.open();
}

function openCurrentWorkflow() {
  transmittal_workflow_modal.patchOptions({
    attrs: {
      onClose() {
        transmittal_workflow_modal.close();
      },
    },
  });
  transmittal_workflow_modal.open();
}
</script>

<template>
  <div class="flex justify-between items-center relative">
    <div v-if="is_detail_page && !is_public_page" class="absolute -left-12 top-0">
      <hawk-button v-if="!read_only" type="text" @click="go_back">
        <icon-hawk-chevron-left class="text-blue-600 text-xl" />
      </hawk-button>
    </div>
    <div>
      <div class="text-lg font-semibold">
        {{ transmittal?.name }}
        <hawk-badge
          v-if="transmittal_status && !is_public_page"
          :color="transmittal_status?.color"
        >
          {{ transmittal_status?.label }}
        </hawk-badge>
      </div>
      <div class="mt-1 text-sm">
        <div> {{ $t('Request No') }}: {{ transmittal?.number || '-' }} </div>
      </div>
    </div>
    <div class="flex">
      <div v-if="transmittal?.workflow_template && transmittal?.workflow && ['approver', 'information'].includes(transmittal.user_role) && transmittal_permissions.checkTransmittalPermission({ permission: 'view_transmittal' })" class="mr-3  overflow-hidden flex items-center">
        <div class="text-primary-600 border rounded-lg border-primary-600 p-1.5 mr-2">
          <IconHawkDataflowOne />
        </div>
        <div>
          <div class="text-sm font-semibold">
            {{ $t('Workflow') }}
          </div>
          <div class="text-xs font-semibold text-primary-600 mt flex cursor-pointer" @click="openCurrentWorkflow">
            <span class="truncate max-w-[256px]">{{ transmittal.workflow.name || 'Template name' }} </span> <IconHawkChevronRight class="w-4 h-4" />
          </div>
        </div>
      </div>
      <div v-if="!is_public_page" class="mr-3 w-[256px]">
        <div class="text-sm font-semibold">
          {{ $t('Progress') }}
        </div>
        <hawk-progress :progress="transmittal.total ? (transmittal.completed / transmittal.total) * 100 : 0">
          {{ transmittal?.completed || 0 }}/{{ transmittal?.total || 0 }}
        </hawk-progress>
      </div>
      <div v-if="can_share_transmittal" class="relative cursor-pointer group/members">
        <HawkButton type="outlined" @click="openShareModal">
          <IconHawkShareSeven v-if="can_share_transmittal" class="h-4 w-4" />
          <template v-if="all_members?.length">
            <HawkMembers :members="all_members?.map(member => member.uid)" size="xs" type="group" />
          </template>
        </HawkButton>
        <TransmittalDetailMembersInfo class="hidden absolute right-0 w-[476px] mt-2 group-hover/members:block" :members="members_info" />
      </div>
      <hawk-menu
        v-if="is_detail_page && !read_only && !is_public_page"
        :items="dropdown_items"
        additional_dropdown_classes="w-52"
        class="ml-2"
      >
        <template #trigger>
          <hawk-button type="outlined" icon>
            <IconHawkSettingsOne class="w-[16px] h-[16px] text-gray-600" />
          </hawk-button>
        </template>
      </hawk-menu>
    </div>
    <HawkExportToast v-if="is_pdf_exporting" :submit="exportPDF" :completed_text="$t('Document exported')" @cancel="is_pdf_exporting = false" @close="is_pdf_exporting = false" />
  </div>
</template>
