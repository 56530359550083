import { useCommonStore } from '~/common/stores/common.store.js';
import { getUserFullName } from '~/common/utils/common.utils';
import { $date } from '~/common/utils/date.util';
import { stringToNode } from '~/dashboard/print/utilities.js';

function createTransmittalDetailsView(chart, index) {
  const page_break_class = index === 0 ? 'dashboard-row--page-break' : '';
  const transmittal = chart.dataSource.transmittal_store.transmittal;
  const transmittal_store = chart.dataSource.transmittal_store;
  const document_status_map = transmittal.review_status_set;
  const common_store = useCommonStore();

  if (!transmittal?.documents?.length)
    return;

  const chart_container = stringToNode(`
    <div class="dashboard-row ${page_break_class}" style="padding-bottom:16px;">
      <div>
        <h3 class="dashboard-row__name" style="font-size: 18px;">
          ${chart.chart_name}
        </h3>
        <div style="margin-bottom: 15px;">
          <div style='font-size: 16px;font-weight: 500;'>${transmittal.name}</div>
          <div style='font-size: 16px;'>${transmittal.number || ''}</div>
        </div>
        <div style="display:grid;grid-template-columns: 1fr 1fr 1fr;grid-gap: 16px;margin-bottom: 15px;background-color: rgb(249, 250, 251);padding:16px;">
          <div>
            <div class="transmittal-widget__name"> Created by </div>
            <div class="transmittal-widget__value"> ${getUserFullName(common_store.get_user(transmittal.owner))}</div>
          </div>
          <div>
            <div class="transmittal-widget__name"> Priority </div>
            <div class="transmittal-widget__value"> ${transmittal.priority ? transmittal_store.priority_values_map[transmittal.priority].label : '-'}</div>
          </div>
          <div>
            <div class="transmittal-widget__name"> Due date </div>
            <div class="transmittal-widget__value"> ${transmittal.due_date ? $date(transmittal.due_date, 'DATE_MED') : '-'}</div>
          </div>
          <div>
            <div class="transmittal-widget__name"> Issue purpose </div>
            <div class="transmittal-widget__value"> ${transmittal.issue_purpose || '-'}</div>
          </div>
          <div>
            <div class="transmittal-widget__name"> Category </div>
            <div class="transmittal-widget__value"> ${transmittal.category_name ? transmittal.category_name : '-'}</div>
          </div>
          <div>
            <div class="transmittal-widget__name"> Status </div>
            <div class="transmittal-widget__value"> ${transmittal_store.transmittal_status(transmittal)?.label}</div>
          </div>
        </div>
        <div class="dashboard-row__name"> Documents (${transmittal.documents.length}) </div>
        <table>
          <thead>
          <tr>
            <th class='transmittal-table__th'>S.NO</th>
            <th class='transmittal-table__th'>Number</th>
            <th class='transmittal-table__th'>Name</th>
            <th class='transmittal-table__th'>Revisions</th>
            <th class='transmittal-table__th'>Status</th>
          </tr>
          </thead>
          <tbody>
          ${transmittal.documents.map((document, index) => `
            <tr>
              <td style="min-width: 50px;">${index + 1}</td>
              <td style="min-width: 200px;max-width: 300px;">${document.number}</td>
              <td style="min-width: 200px;max-width: 300px;">${document.name}</td>
              <td style="min-width: 200px;max-width: 300px;">${document?.versions?.length || 1}</td>
              <td style="min-width: 50px;">${document_status_map[document.status]}</td>
            </tr>  
          `).join('')}
          </tbody>
        </table>
      </div>
    </div>
  `);

  return chart_container;
}

export { createTransmittalDetailsView };
