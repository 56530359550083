<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { Validator } from '@vueform/vueform';
import { useModal } from 'vue-final-modal';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

import EmailPopup from '~/forms/components/form-workflows/sidebar/email-block/email-popup.vue';

const props = defineProps({
  published: {
    type: Boolean,
  },
  disabled: {
    type: Boolean,
  },
  is_sub_step: {
    type: Boolean,
  },
  sub_step: {
    type: Object,
  },
  submit: {
    type: Function,
    default: () => {
      return true;
    },
  },
  existing_members: {
    type: Array,
  },
});

const emit = defineEmits(['close']);

const auth_store = useAuthStore();
const { $t } = useCommonImports();

const custom_emails = Object.assign({}, props.sub_step?.reminders?.config?.map(reminder => reminder?.customEmail) || []);

const state = reactive({
  is_advanced_options: props.sub_step?.reminders?.is_enabled || props.sub_step?.escalations?.is_enabled,
  escalation_email: props.sub_step?.escalations?.custom_email || null,
  reminders_email: custom_emails,
});

const reminders_email_popup = useModal({
  component: EmailPopup,
  attrs: {
    onClose() {
      reminders_email_popup.close();
    },
  },
});

function openEmailPopup(index) {
  reminders_email_popup.patchOptions({
    attrs: {
      popup_title: $t('Custom Email'),
      is_popup: true,
      has_dynamic_fields: false,
      form: state.reminders_email?.[index],
      onSave(val) {
        state.reminders_email[index] = val;
        reminders_email_popup.close();
      },
    },
  });
  reminders_email_popup.open();
}

const escalations_email_popup = useModal({
  component: EmailPopup,
  attrs: {
    onClose() {
      escalations_email_popup.close();
    },
    onSave(val) {
      state.escalation_email = val;
      escalations_email_popup.close();
    },
  },
});

function openEscalationEmailPopup() {
  escalations_email_popup.patchOptions({
    attrs: {
      popup_title: $t('Custom Email'),
      is_popup: true,
      has_dynamic_fields: false,
      form: state.escalation_email,
    },
  });
  escalations_email_popup.open();
}

const sub_step = computed(() => props.sub_step);
const is_sub_step = computed(() => props.is_sub_step);

const form$ = ref(null);

const membersCount = class extends Validator {
  check(value) {
    return form$.value?.data?.members?.length >= value;
  }

  get message() {
    return 'The entered value should not be greater than the members count';
  }
};

const existing_members_excluding_current_user = new Set([...(props.existing_members || []), auth_store.logged_in_user_details?.user_id]);

const existing_members = computed(() => {
  return props.published ? Array.from(existing_members_excluding_current_user) : props.existing_members; // Filter out the current user while creating transmittal
});
</script>

<template>
  <hawk-modal-container content_class="rounded-lg w-[600px]">
    <Vueform
      ref="form$"
      :default="sub_step"
      :presets="['']"
      size="sm"
      :display-errors="false"
      :endpoint="(data, form) => props.submit(data, form, state)"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, wrapper: 12, label: 4 },
      }"
      @mounted="form$ => props.sub_step ? form$.load(props.sub_step, true) : null"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <span v-if="sub_step"> {{ $t('Edit') }} </span>
            <span v-else-if="is_sub_step"> {{ $t('Add sub step') }} </span>
            <span v-else> {{ $t('Add step') }} </span>
          </template>
        </hawk-modal-header>
        <hawk-modal-content :class="{ 'pointer-events-none': disabled }">
          <TextElement name="name" :disabled="published || disabled" :label="$t('Name')" />
          <RadiogroupElement
            class="mt-4"
            :label="$t('Condition')"
            rules="required"
            :disabled="published || disabled"
            name="condition"
            :items="[
              { label: $t('Require all members to approve'), value: 'AND' },
              { label: $t('Require any members to approve'), value: 'OR' },
            ]"
          />
          <hawk-assignee-input
            class="mt-4"
            :multi="true"
            :format_load="true"
            :options="{
              label: $t('Add members'),
              name: 'members',
              existing_users: existing_members,
              disabled,
              rules: 'required',
              placeholder: $t('Add members and teams'),
            }"
          />
          <TextElement
            name="min_approvals" :label="$t('Minimum Approval')" input-type="number" class="mt-4" :default="1"
            :conditions="[
              ['condition', '==', 'OR'],
            ]"
            :rules="['required', 'numeric', 'min:1', membersCount]"
          />
          <RadiogroupElement
            v-if="!(sub_step || is_sub_step)"
            class="mt-4"
            rules="required"
            :label="$t('If rejected')"
            name="on_reject"
            :items="[
              { label: 'Continue to the next block', value: 'continue' },
              { label: 'Back to the initiator', value: 'revert' },
            ]"
          />
          <div class="mt-8 cursor-pointer w-40" @click="state.is_advanced_options = !state.is_advanced_options">
            <div class="flex items-center text-primary-700 text-sm font-semibold">
              <IconHawkChevronRight v-if="!state.is_advanced_options" class="w-6 h-6" />
              <IconHawkChevronDown v-else class="w-6 h-6" />
              {{ $t('Advanced options') }}
            </div>
          </div>
          <div v-if="state.is_advanced_options">
            <ObjectElement id="escalations" name="escalations" :disabled="disabled">
              <div class="flex gap-4 my-4 col-span-12">
                <div class="flex-1">
                  <div class="text-sm font-semibold">
                    {{ $t("Escalate") }}
                  </div>
                  <div class="text-xs w-80">
                    {{
                      $t(
                        "Escalate to other members if there is no outcome within in the defined time period",
                      )
                    }}.
                  </div>
                </div>
                <ToggleElement
                  name="is_enabled"
                  :disabled="disabled"
                />
              </div>
              <div v-if="form$?.data?.escalations?.is_enabled" class="col-span-12 mb-4">
                <RadiogroupElement
                  :disabled="disabled"
                  name="type"
                  default="add"
                  :items="[
                    { value: 'add', label: $t('Add members') },
                    {
                      value: 'replace',
                      label: $t('Replace members'),
                    },
                  ]"
                  :override-classes="{
                    RadiogroupElement: {
                      wrapper: 'three_columns',
                    },
                  }"
                />
                <hawk-assignee-input
                  class="mt-4"
                  :multi="true"
                  :format_load="true"
                  :options="{
                    label: $t('Choose members'),
                    name: 'members_list',
                    description: $t('The members selected will be added as reviewers of this step.'),
                    disabled,
                    rules: 'required',
                    formatData: (k, v) => ({ [k]: v.map(user => user.uid || user) }),
                    placeholder: $t('Choose members'),
                  }"
                />
                <ObjectElement name="config" class="mt-2">
                  <TextElement
                    name="days"
                    :disabled="disabled"
                    input-type="number"
                    :label="$t('Escalate')"
                    :default=" 1"
                    :add-classes="{ ElementLayout: { innerContainer: '!flex items-center', innerWrapper: 'w-24' } }"
                    :rules="['required', 'integer', 'min:1']"
                    autocomplete="off"
                  >
                    <template #after>
                      <span class="ml-2">
                        {{ $t('after') }} {{ $t('due date') }}
                      </span>
                    </template>
                    <template #addon-after>
                      {{ $t('days') }}
                    </template>
                  </TextElement>
                </ObjectElement>
                <StaticElement :label="$t('Custom Email')" class="mt-6">
                  <HawkButton class="mr-4 -mt-4" type="link" @click="openEscalationEmailPopup">
                    {{ state.escalation_email?.subject ? $t('Edit') : $t('Add') }}
                  </HawkButton>
                </StaticElement>
              </div>
            </ObjectElement>
            <div class="border-b" />
            <ObjectElement name="reminders" :disabled="disabled">
              <div class="flex gap-4 my-4 col-span-12">
                <div class="flex-1">
                  <div class="text-sm font-semibold">
                    {{ $t("Reminders") }}
                  </div>
                  <div class="text-xs w-80">
                    {{
                      $t(
                        "Configure reminders to send periodic emails to pending with reviewers to take an action",
                      )
                    }}.
                  </div>
                </div>
                <ToggleElement
                  :disabled="disabled"
                  name="is_enabled"
                />
              </div>
              <div v-if="form$?.data?.reminders?.is_enabled" class="col-span-12">
                <ListElement
                  name="config"
                  :disabled="disabled"
                  :add-text="`+ ${$t('Add reminder')}`"
                  :presets="['repeatable_list']"
                  rules="required"
                  min="1"
                  :override-classes="{
                    ListElement: {
                      listItem: 'flex w-full bg-gray-50 p-4',
                      remove: ['h-5 w-5 ml-auto'],
                    },
                  }"
                >
                  <template #default="{ index }">
                    <ObjectElement :embed="true" :name="index">
                      <RadiogroupElement
                        name="is_once"
                        :disabled="disabled"
                        :label="$t('Frequency')"
                        class="mb-2"
                        :default="false"
                        :remove-class="{
                          wrapper: 'flex-col',
                        }"
                        :add-classes="{
                          RadiogroupRadio: { container: 'mr-4' },
                        }"
                        :items="[{
                                   value: true,
                                   label: $t('Once'),
                                 },
                                 {
                                   value: false,
                                   label: $t('Multiple times'),
                                 }]"
                      />
                      <FormWorkflowBlockReminders :form-data="{ reminders: form$?.data?.reminders?.config?.[index] }" :payload-data="{}" />
                      <StaticElement :label="$t('Custom Email')" class="mt-6">
                        <HawkButton class="mr-4 -mt-4" type="link" @click="openEmailPopup(index)">
                          {{ state.reminders_email?.[index]?.subject ? $t('Edit') : $t('Add') }}
                        </HawkButton>
                      </StaticElement>
                    </ObjectElement>
                  </template>
                </ListElement>
              </div>
            </ObjectElement>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer v-if="!disabled">
          <template #right>
            <div class="col-span-12">
              <div class="flex justify-end w-full">
                <HawkButton type="outlined" text="Cancel" class="mr-4" @click="emit('close')">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement submits size="sm" name="submit" :button-label="$t('Save')" button-class="vf-btn-primary" />
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
