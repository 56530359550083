import { h, inject } from 'vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import HawkMembers from '~/common/components/organisms/hawk-members-badge/hawk-members.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import TransmittalReviewStatus from '~/dms/components/transmittals/transmittal-review-status.vue';

export function useTransmittalSidebarConfiguration(transmittal, document) {
  const owner = transmittal.owner;
  const $date = inject('$date');
  const { $t } = useCommonImports();
  const auth_store = useAuthStore();

  const get_member_render_fn = members => h(HawkMembers, { members: members || owner, type: 'badge', max_badges_to_display: 1, class: 'mx-2 align-middle' });
  const get_status_render_fn = status => h(TransmittalReviewStatus, { document: { status }, document_status_map: transmittal.review_status_set });

  const delegation_message = () => {
    if (document?.delegated_by?.length > 0)
      return document.delegated_by.map(delegation => [get_member_render_fn(delegation.user), `${$t('delegated the document to you on')} ${$date(delegation?.timestamp, 'L_DATE_MED')} ${$t('for submission')}`]);
    else if (document.has_delegate)
      return [$t('You delegated the submission to'), get_member_render_fn(document.delegated_to.user), `${$t('member on')} ${$date(document.delegated_to?.timestamp, 'L_DATE_MED')}. ${$t('You can still submit the document')}.`];
  };

  const reviewers_delegation_message = () => {
    if (document?.delegated_by?.length > 0)
      return document.delegated_by.map(delegation => [get_member_render_fn(delegation.user), `${$t('delegated the document to you for review on')} ${$date(delegation?.timestamp, 'L_DATE_MED')}`]);
    else if (document?.review_requested_by?.length > 0)
      return document.review_requested_by.map(requester => [get_member_render_fn(requester.user), `${$t('requested you to review the document by')} ${$date(requester?.timestamp, 'L_DATE_MED')}`]);
    else if (document.has_delegate)
      return [$t('You delegated the review to'), get_member_render_fn(document.delegated_to.user), `${$t('member on')} ${$date(document.delegated_to?.timestamp, 'L_DATE_MED')}. ${$t('You can still review the document')}.`];
    else if (document?.has_additional_reviewers)
      return $t('You requested other members to review the document. Check the feedback section below for more details.');
  };

  const reviewers_warning_message = () => {
    if (document.status === 'pending_review') {
      if (document.current_workflow_step)
        return `${$t('Review the document for')} ${document.current_workflow_step.name} ${$t('by')} ${$date(document.due_date, 'L_DATE_MED')}`;
      else
        return `${$t('Review the document by')} ${$date(document.due_date, 'L_DATE_MED')}`;
    }

    else {
      return [$t('You marked the document as '), get_status_render_fn(document.status), `. ${$t('Waiting for approvers to take action.')}`];
    }
  };

  const all_config = [
    {
      scenario: 'Needs to submit the document',
      conditions: () => !transmittal.cancelled && !document.cancelled && !document.synced && document.user_role === 'submitter' && document.mode === 'submit' && document.approval_status === 'pending_review',
      components: { warning: true, info: document?.delegated_by?.length || document.has_delegate, submission: true },
      warning_data: () => `${$t('Submit the document by')} ${$date(document?.due_date, 'L_DATE_MED')}`,
      info_data: delegation_message,
    },
    {
      scenario: 'Document submitted - waiting for review',
      conditions: () => !transmittal.cancelled && !document.cancelled && !document.synced && document.user_role === 'submitter' && document.mode === 'review' && document.submission_status === 'submitted' && document.approval_status === 'pending_review',
      components: { preview: true, info: true, submission: true },
      info_data: () => [get_member_render_fn(document?.submitted_by?.user), `${$t('submitted the document on')} ${$date(document.submitted_by?.timestamp, 'L_DATE_MED')}. ${$t('It is waiting for review')}`],
    },
    {
      scenario: 'Document requested for resubmission',
      conditions: () => !transmittal.cancelled && !document.cancelled && !document.synced && document.user_role === 'submitter' && document.mode === 'submit' && document.approval_status === 'revise_resubmit',
      components: { preview: true, warning: true, info: document?.delegated_by?.length || document.has_delegate, submission: true, feedback: transmittal?.feedback_visibility?.includes('submitter') },
      warning_data: () => [get_member_render_fn(document?.approval_by?.user), 'marked the document as ', get_status_render_fn(document.approval_status), ` on ${$date(document.approval_by?.timestamp, 'L_DATE_MED')}. ${$t('Submit a new version')}`],
      info_data: delegation_message,
    },
    {
      scenario: 'Document finalized',
      conditions: () => !transmittal.cancelled && !document.cancelled && !document.synced && document.user_role === 'submitter' && document.mode === 'review' && !['revise_resubmit', 'pending_review'].includes(document.approval_status),
      components: { preview: true, info: true },
      info_data: () => [get_member_render_fn(document?.approval_by?.user), $t('marked the document as '), get_status_render_fn(document.approval_status), ` on ${$date(document.approval_by?.timestamp, 'L_DATE_MED')}.`],
    },
    {
      scenario: 'In a different workflow i.e waiting for other transmittal',
      conditions: () => !transmittal.cancelled && !document.cancelled && !document.synced && ['approver', 'reviewer', 'consolidator'].includes(document.user_role) && document.mode === 'submit' && document.approval_status === 'pending_review' && document.waiting_on?.number,
      components: { preview: true, warning: true },
      warning_data: () => `${$t('Waiting for the review from another transmittal')} - ${document.waiting_on?.number}`,
    },
    {
      scenario: 'Waiting for submission',
      conditions: () => !transmittal.cancelled && !document.cancelled && !document.synced && ['approver', 'reviewer', 'consolidator'].includes(document.user_role) && document.mode === 'submit' && document.submission_status === 'pending',
      components: { preview: true, info: true },
      info_data: () => $t('Waiting for the document from submitters'),
    },
    {
      scenario: 'Need to provide review',
      conditions: () => !transmittal.cancelled && !document.cancelled && !document.synced && ['approver', 'reviewer', 'consolidator'].includes(document.user_role) && document.mode === 'review' && document.approval_status === 'pending_review' && document.submission_status !== 'pending' && document.actions.can_review,
      components: { preview: true, warning: true, info: document?.delegated_by?.length || document.has_delegate || document?.review_requested_by?.length || document?.has_additional_reviewers, feedback: ((document.user_role === 'approver') || (document.user_role === 'consolidator' && document.has_additional_reviewers) || (document.user_role === 'reviewer' && transmittal?.feedback_visibility?.includes('reviewer'))), submission: true },
      info_data: reviewers_delegation_message,
      warning_data: reviewers_warning_message,
    },
    {
      scenario: 'Additional review provided',
      conditions: () => !transmittal.cancelled && !document.cancelled && !document.synced && ['reviewer', 'consolidator'].includes(document.user_role) && document.mode === 'review' && document.approval_status === 'pending_review' && (document.submission_status === 'submitted') && !document.actions.can_review,
      components: { preview: true, info: true, submission: document.reviewer_feedback?.[auth_store?.logged_in_user_details?.user_id] },
      submission_data: () => ({ uid: 'feedback', draft: document.reviewer_feedback?.[auth_store?.logged_in_user_details?.user_id] }),
      info_data: () => [$t('You marked the document as '), get_status_render_fn(document.reviewer_feedback?.[auth_store?.logged_in_user_details?.user_id]?.status || document.status), `. ${$t('Waiting for approvers to take action')}`],
    },
    {
      scenario: 'review provided',
      conditions: () => !transmittal.cancelled && !document.cancelled && !document.synced && ['approver', 'reviewer', 'consolidator'].includes(document.user_role) && document.approval_status !== 'pending_review' && !document.actions.can_review,
      components: { preview: true, info: true, sync: document?.actions?.can_sync, submission: true },
      info_data: () => [get_member_render_fn(document?.approval_by?.user), $t('marked the document as '), get_status_render_fn(document.approval_status), ` on ${$date(document.approval_by?.timestamp, 'L_DATE_MED')}.`],
    },
    {
      scenario: 'Completed waiting on transmittal. Need to provide review',
      conditions: () => !transmittal.cancelled && !document.cancelled && !document.synced && ['approver', 'reviewer', 'consolidator'].includes(document.user_role) && document.approval_status === 'pending_review' && !document.waiting_on?.number && document.waited_on?.number,
      components: { preview: true, info: true, warning: true, submission: true },
      warning_data: () => `${$t('You are requested to review the document by')} ${$date(document?.due_date, 'L_DATE_MED')}`,
      info_data: () => [$t('Document marked as '), get_status_render_fn(document.waited_on?.[-1]?.status), ` in ${document.waited_on?.[-1]?.number}`],
    },
    {
      scenario: 'waiting for submission',
      conditions: () => !transmittal.cancelled && !document.cancelled && !document.synced && document.user_role === 'information' && document.submission_status === 'pending',
      components: { preview: true, info: true },
      info_data: () => $t('Waiting for the document from submitters'),
    },
    {
      scenario: 'waiting for review',
      conditions: () => !transmittal.cancelled && !document.cancelled && !document.synced && document.user_role === 'information' && document.submission_status === 'submitted' && document.approval_status === 'pending_review',
      components: { preview: true, info: true },
      info_data: () => $t('Waiting for review from reviewers/approvers.'),
    },
    {
      scenario: 'Review provided',
      conditions: () => !transmittal.cancelled && !document.cancelled && !document.synced && document.user_role === 'information' && document.submission_status === 'submitted' && document.approval_status !== 'pending_review',
      components: { preview: true, info: true },
      info_data: () => [get_member_render_fn(document?.approval_by?.user), $t('marked the document as '), get_status_render_fn(document.approval_status), ` on ${$date(document?.approval_by?.timestamp, 'L_DATE_MED')}.`],
    },
    {
      scenario: 'Document synced',
      conditions: () => !transmittal.cancelled && !document.cancelled && document.synced,
      components: { preview: true, info: true },
      info_data: () => [get_member_render_fn(document?.latest_action?.user), `${$t('synced the document on')} ${$date(document?.latest_action?.timestamp, 'L_DATE_MED')}.`],
    },
    {
      scenario: 'Document cancelled',
      conditions: () => !transmittal.cancelled && document.cancelled,
      components: { preview: true, info: true },
      info_data: () => [get_member_render_fn(document?.latest_action?.user), `${$t('cancelled the document on')} ${$date(document?.latest_action?.timestamp, 'L_DATE_MED')}.`],
    },
    {
      scenario: 'Transmittal cancelled',
      conditions: () => transmittal.cancelled,
      components: { preview: true, info: true },
      info_data: () => [get_member_render_fn(transmittal?.latest_action?.user), `${$t('cancelled the transmittal on')} ${$date(transmittal?.latest_action?.timestamp, 'L_DATE_MED')}.`],
    },
  ];

  const matching_config = all_config.find(config => config.conditions());

  return matching_config || { components: { preview: true, submission: true } };
}
