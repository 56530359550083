<script setup>
import { useElementSize } from '@vueuse/core';
import { includes } from 'lodash-es';
import { computed, inject, onBeforeUnmount, onMounted, provide, ref, watch } from 'vue';
import { useModal } from 'vue-final-modal';
import { useAuthStore } from '~/auth/stores/auth.store';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import { $date } from '~/common/utils/date.util';

import DashboardWidgetForm from '~/dashboard/pages/dashboard-widget-form.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

// ASSET WIDGETS

import AssetNumberChart from '~/dashboard/components/widgets/asset-widgets/asset-number-chart.widget.vue';
import AssetPivotTable from '~/dashboard/components/widgets/asset-widgets/asset-pivot-table.vue';
import AssetProgressCard from '~/dashboard/components/widgets/asset-widgets/asset-progress-card.widget.vue';
import FormActivitiesTableWidget from '~/dashboard/components/widgets/form-widgets/form-activities-table.widget.vue';

import ActivityHistory from '~/dashboard/components/widgets/form-widgets/form-activity-history.widget.vue';
import FormsListWidget from '~/dashboard/components/widgets/form-widgets/form-list.widget.vue';

import FormsListWithTabsFiltersWidget from '~/dashboard/components/widgets/form-widgets/form-list-with-tabs-filters.widget.vue';
import FormMapView from '~/dashboard/components/widgets/form-widgets/form-map-view.widget.vue';
import FormNumberChartWidget from '~/dashboard/components/widgets/form-widgets/form-number-chart.widget.vue';
import FormResponseTableWidget from '~/dashboard/components/widgets/form-widgets/form-response-table.widget.vue';
import FormSingleView from '~/dashboard/components/widgets/form-widgets/form-single-view.widget.vue';
import AssetGraphWidget from '~/dashboard/components/widgets/graph-widgets/asset-graph.widget.vue';
// TASK WIDGETS
import TaskGraphWidget from '~/dashboard/components/widgets/graph-widgets/task-graph.widget.vue';
import TasksListWidget from '~/dashboard/components/widgets/task-widgets/task-list.widget.vue';
// FORM WIDGETS
import FormGraphWidget from '~/dashboard/components/widgets/graph-widgets/form-graph.widget.vue';

// THERM WIDGETS
import ThermGraphWidget from '~/dashboard/components/widgets/graph-widgets/therm-graph.widget.vue';

// TERRA WIDGETS
import TerraGraphWidget from '~/dashboard/components/widgets/graph-widgets/terra-graph.widget.vue';
import TerraActivitiesTableWidget from '~/dashboard/components/widgets/terra-widgets/terra-activities-table.widget.vue';
import TerraAnalyticsPivotTableWidget from '~/dashboard/components/widgets/terra-widgets/terra-analytics-pivot-table.widget.vue';
import TerraFeatureTableWidget from '~/dashboard/components/widgets/terra-widgets/terra-feature-table.widget.vue';
import TerraMapViewWidget from '~/dashboard/components/widgets/terra-widgets/terra-map-view.widget.vue';
import TerraPivotTableWidget from '~/dashboard/components/widgets/terra-widgets/terra-pivot-table.widget.vue';

// PM WIDGETS
import PmGraphWidget from '~/dashboard/components/widgets/graph-widgets/pm-graph.widget.vue';
import PmActivitiesTableWidget from '~/dashboard/components/widgets/pm-widgets/pm-activities-table.widget.vue';
import PmActivityHistoryTable from '~/dashboard/components/widgets/pm-widgets/pm-activity-history-table.widget.vue';
import PmIndexWidget from '~/dashboard/components/widgets/pm-widgets/pm-index.widget.vue';
import PmNumberChartWidget from '~/dashboard/components/widgets/pm-widgets/pm-number-chart.widget.vue';
import PmProjectsListWidget from '~/dashboard/components/widgets/pm-widgets/pm-projects-list.widget.vue';
import PmScurveTableWidget from '~/dashboard/components/widgets/pm-widgets/pm-scurve-table.widget.vue';

// DMS WIDGETS

import unsupportedWidget from '~/dashboard/components/widgets/common-widgets/unsuported-widget.vue';
import ContainerViewsWidget from '~/dashboard/components/widgets/custom-widgets/container-views.widget.vue';
import CustomTableWidget from '~/dashboard/components/widgets/custom-widgets/custom-table.widget.vue';
import FileListWidget from '~/dashboard/components/widgets/dms-widgets/files-list.widget.vue';

import TransmittalDetailWidget from '~/dashboard/components/widgets/dms-widgets/transmittal-detail.widget.vue';
import TransmittalListWidget from '~/dashboard/components/widgets/dms-widgets/transmittal-list.widget.vue';
import DMSGraphWidget from '~/dashboard/components/widgets/graph-widgets/dms-graph.widget.vue';
// INVENTORY WIDGET
import InventoryMaterialTrackingWidget from '~/dashboard/components/widgets/inventory-widgets/inventory-material-tracking.widget.vue';

import InventoryReportsTableWidget from '~/dashboard/components/widgets/inventory-widgets/inventory-reports-table.widget.vue';
import InventoryStockTrackingWidget from '~/dashboard/components/widgets/inventory-widgets/inventory-stock-tracking.widget.vue';
import InventoryTransactionsPivotWidget from '~/dashboard/components/widgets/inventory-widgets/inventory-transactions-pivot.widget.vue';
// CUSTOM WIDGETS
import RichTextWidget from '~/dashboard/components/widgets/custom-widgets/rich-text.widget.vue';
import CustomGraphWidget from '~/dashboard/components/widgets/graph-widgets/custom-graph.widget.vue';

const props = defineProps({
  config: {
    type: Object,
    default: () => ({}),
  },
  isPreviewing: {
    type: Boolean,
    default: false,
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  previewingContentHeight: {
    type: Number,
  },
  canModifyResource: {
    type: Boolean,
    default: false,
  },
  activeSelectedRange: {
    type: Array,
    default: () => [],
  },
});

const $t = inject('$t');
const is_loading_pdf = inject('is_loading_pdf');

const dashboard_store = useDashboardStore();
const auth_store = useAuthStore();

const widget_header = ref(null);
const { height } = useElementSize(widget_header);

const edit_widget_modal = useModal({
  component: DashboardWidgetForm,
  attrs: {
    onClose() {
      edit_widget_modal.close();
    },
  },
});
function editWidget() {
  dashboard_store.set_edit_widget_data(props.config);
  edit_widget_modal.open();
}

const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
});
function deleteWidget() {
  patchOptions(
    {
      attrs: {
        header: $t('Delete Widget'),
        content: `Are you sure you want to delete ${props.config.data.name || $t('this widget')}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          await dashboard_store.delete_widget(props.config);
          closeDeletePopup();
        },
      },
    },
  );
  openDeletePopup();
}

function set_asset_module_components() {
  let type;
  switch (props.config.data.type) {
    case 'metric':
      type = AssetNumberChart;
      break;
    case 'progress_card':
      type = AssetProgressCard;
      break;
    case 'vertical_graph':
    case 'horizontal_bar':
    case 'donut' :
      type = AssetGraphWidget;
      break;
    case 'asset_pivot_table':
      type = AssetPivotTable;
      break;
  }
  return type;
}
function set_task_module_components() {
  let type;
  switch (props.config.data.type) {
    case 'tasks_list':
      type = TasksListWidget;
      break;
    case 'vertical_graph':
    case 'horizontal_bar':
    case 'donut' :
      type = TaskGraphWidget;
      break;
  }
  return type;
}
function set_forms_module_components() {
  let type;
  switch (props.config.data.type) {
    case 'area':
    case 'status_breakdown':
    case 'assignee_breakdown':
    case 'asset_breakdown':
    case 'step_history':
    case 'vertical_graph':
    case 'horizontal_bar':
    case 'donut':
    case 'line':
      type = FormGraphWidget;
      break;
    case 'forms_list_with_tabs':
      type = FormsListWithTabsFiltersWidget;
      break;
    case 'forms_list':
      type = FormsListWidget;
      break;
    case 'form_responses':
      type = FormResponseTableWidget;
      break;
    case 'single_form_view':
      type = FormSingleView;
      break;
    case 'activities_table':
      type = FormActivitiesTableWidget;
      break;
    case 'number_chart':
      type = FormNumberChartWidget;
      break;
    case 'activity_history':
      type = ActivityHistory;
      break;
    case 'features_map':
      type = FormMapView;
      break;
  }
  return type;
}
function set_therm_module_components() {
  let type;
  switch (props.config.data.type) {
    case 'defects_by_inverter':
    case 'temperature_histogram':
    case 'severity_graph':
      type = ThermGraphWidget;
      break;
  }
  return type;
}
function set_terra_module_components() {
  let type;
  switch (props.config.data.type) {
    case 'feature_type_counts':
      switch (props.config.data.chart_type) {
        case 'pivot table':
          type = unsupportedWidget;
          break;
        case 'feature_type_table':
          type = TerraFeatureTableWidget;
          break;
        default:
          type = TerraGraphWidget;
          break;
      }
      break;
    case 'map-view':
      type = TerraMapViewWidget;
      break;
    case 'workprogress_terra_chart': // work performed
      type = TerraActivitiesTableWidget;
      break;
    case 'workflow_pivot_table': // Activity progress table
      type = TerraPivotTableWidget;
      break;
    case 'terra_pivot_table': // Analytics Pivot table
      type = TerraAnalyticsPivotTableWidget;
      break;
      // default:
      //   type = TerraActivitiesTableWidget;
      //   break;
  }
  return type;
}
function set_schedule_module_components() {
  let type;

  switch (props.config.data.type) {
    case 's_curve':
      switch (props.config.data.chart_type) {
        case 'bar':
          type = PmGraphWidget;
          break;
        case 'table':
          type = PmScurveTableWidget;
          break;
        default:
          type = PmGraphWidget;
          break;
      }
      break;
    case 'project_progress':
    case 'project_duration':
    case 'planned_vs_actual':
    case 'progress_history':
    case 'vertical_graph':
    case 'horizontal_bar':
    case 'donut':
    case 'activities_breakdown':
      type = PmGraphWidget;
      break;
    case 'spi':
    case 'cpi':
      type = PmIndexWidget;
      break;
    case 'activities_metrics':
      type = PmNumberChartWidget;
      break;
    case 'activities_work_combined_table':
      type = PmActivitiesTableWidget;
      break;
    case 'projects_list':
      type = PmProjectsListWidget;
      break;
    case 'activity_history_table':
      type = PmActivityHistoryTable;
      break;
    case 'activities_work_table':
    case 'activities_table':
      type = unsupportedWidget;
      break;
  }
  return type;
}
function set_dms_module_components() {
  let type;
  switch (props.config.data.type) {
    case 'vertical_graph' :
    case 'horizontal_bar' :
    case 'donut' :
      type = DMSGraphWidget;
      break;
    case 'transmittal_detail_view':
      type = TransmittalDetailWidget;
      break;
    case 'documents_list':
      type = FileListWidget;
      break;
    case 'transmittal_list':
      type = TransmittalListWidget;
      break;
  }
  return type;
}
function set_inventory_module_components() {
  let type;
  switch (props.config.data.type) {
    case 'material_tracking' :
      type = InventoryMaterialTrackingWidget;
      break;
    case 'stock_tracking' :
      type = InventoryStockTrackingWidget;
      break;
    case 'transactions_pivot':
      type = InventoryTransactionsPivotWidget;
      break;
    case 'adjustment':
    case 'transaction':
    case 'to_status':
      type = InventoryReportsTableWidget;
      break;
  }
  return type;
}
function set_custom_module_components() {
  let type;
  switch (props.config.data.type) {
    case 'rich_text':
      type = RichTextWidget;
      break;
    case 'vertical_graph' :
    case 'horizontal_bar' :
    case 'line':
    case 'area':
    case 'donut' :
      type = CustomGraphWidget;
      break;
    case 'custom_table':
      type = CustomTableWidget;
      break;
    case 'container_views' :
      type = ContainerViewsWidget;
      break;
  }
  return type;
}

const widget_component = computed(() => {
  let type = '';
  if (props.config.data.module === 'assets') {
    type = set_asset_module_components();
  }
  else if (props.config.data.module === 'tasks') {
    type = set_task_module_components();
  }
  else if (props.config.data.module === 'forms') {
    type = set_forms_module_components();
  }
  else if (props.config.data.module === 'therm') {
    type = set_therm_module_components();
  }
  else if (props.config.data.module === 'terra') {
    type = set_terra_module_components();
  }
  else if (props.config.data.module === 'schedule') {
    type = set_schedule_module_components();
  }
  else if (props.config.data.module === 'dms') {
    type = set_dms_module_components();
  }
  else if (props.config.data.module === 'inventory') {
    type = set_inventory_module_components();
  }
  else if (props.config.data.module === 'custom') {
    type = set_custom_module_components();
  }
  return type;
});

const grid_item_class = computed(() => {
  if (props.isPreviewing)
    return 'max-h-full my-6';
  else if (props.isEditing)
    return 'rounded-[12px] p-1 bg-white border editing-border';
  else if (dashboard_store.is_mobile_view)
    return 'rounded-[12px] p-2 bg-white border m-2';
  else return 'rounded-[12px] p-1 bg-white border ';
});

const has_refresh_button = computed(() => {
  return !(props.config.data.type === 'map-view' || (props.config.module === 'custom' && props.config.data.type !== 'container_views'));
});

const show_edit_controls = computed(() => {
  if (dashboard_store.scope === 'inventory')
    return false;
  return props.isEditing && props.canModifyResource;
});

function getHeight() {
  let height_offset = 0;
  if (props.previewingContentHeight > 0) {
    return props.previewingContentHeight;
  }
  else {
    if ([
      'activities_work_combined_table',
      'workprogress_terra_chart',
      'workflow_pivot_table',
      'activities_table',
      'form_responses',
    ].includes(props.config.data.type)) {
      height_offset = 56;
    }

    else {
      height_offset = 32;
    }

    return (props?.config?.h * 24.25) - (height.value + height_offset);
  }
}

const forceUpdate = ref(0);

function refreshWidget() {
  forceUpdate.value++;
}

const widget_component_ref = ref(null);

async function fetchMapViewData() {
  if (props.config.data.type === 'map-view') {
    await widget_component_ref.value.fetchMapViewData();
    return true;
  }
}

defineExpose({ fetchMapViewData });

const visible_widgets = reactive([]);

const observer = ref(null);
function handleObserver() {
  const grid_items = document.querySelectorAll('.vue-grid-item');

  observer.value = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const itemId = entry.target.dataset.id;
        visible_widgets.push(itemId);
        observer.value.unobserve(entry.target);
      }
    });
  }, {
    root: null, // Use the viewport as the root
    rootMargin: '100px',
    threshold: 0,
  });

  grid_items.forEach((widget) => {
    observer.value.observe(widget);
  });
}

onMounted(() => {
  if (auth_store.check_split('lazy_load_dashboard') && !dashboard_store.is_mobile_view && !props.isPreviewing)
    handleObserver();
});

onBeforeUnmount(() => {
  disconnectObserver();
});
function disconnectObserver() {
  observer?.value?.disconnect();
  observer.value = null;
}

watch(is_loading_pdf, (nv) => {
  if (nv) {
    visible_widgets.push(props.config.uid);
    disconnectObserver();
  }
});

const show_component = computed(() => {
  if (!auth_store.check_split('lazy_load_dashboard') || dashboard_store.is_mobile_view || props.isPreviewing)
    return true;
  else return visible_widgets.includes(props.config.uid);
});

const has_dashboard_selected_range = computed(() => {
  if (dashboard_store?.current_dashboard?.selected_date_range?.active_range?.length !== 2) {
    return false;
  }

  if (props.config.data.type === 'activity_history_table') {
    return props.config.data.activity_history_range === 'selected_range';
  }
  return includes(JSON.stringify(props.config), 'dashboard_active_selected_range');
});

provide('dashboardSelectedRange', has_dashboard_selected_range.value
  ? {
      start: $date(dashboard_store?.current_dashboard?.selected_date_range?.active_range[0], 'MMM DD, hh:mm A'),
      end: $date(dashboard_store?.current_dashboard?.selected_date_range?.active_range[1], 'MMM DD, hh:mm A'),
    }
  : null);
</script>

<template>
  <component
    :is="props.isPreviewing || dashboard_store.is_mobile_view ? 'div' : 'grid-item'"
    :class="dashboard_store.scope !== 'inventory' ? grid_item_class : ''"
    class="overflow-hidden"
    :data-id="props.config.uid"
  >
    <component
      :is="widget_component"
      v-if="widget_component && show_component"
      :id="props.isPreviewing ? 'preview' : props.config.uid"
      ref="widget_component_ref"
      :key="forceUpdate"
      :data="props.config"
      :content_height="getHeight()"
      :is_editing="isEditing"
      :is_mobile_view="dashboard_store.is_mobile_view"
      :active-selected-range="has_dashboard_selected_range ? activeSelectedRange : []"
    >
      <template
        v-if="!props.isPreviewing"
        #header-title
      >
        <div class="font-medium flex">
          <p
            class="truncate mr-2"
          >
            {{ props.config.data.name || $t('Untitled') }}
          </p>
          <p v-if="has_dashboard_selected_range">
            <HawkBadge color="sky">
              {{ $date(dashboard_store?.current_dashboard?.selected_date_range?.active_range[0], 'MMM DD, hh:mm A') }} - {{ $date(dashboard_store?.current_dashboard?.selected_date_range?.active_range[1], 'MMM DD, hh:mm A') }}
            </HawkBadge>
          </p>
        </div>
      </template>
      <template
        v-if="!props.isPreviewing"
        #header-actions
      >
        <div v-if="show_edit_controls" class="flex items-center justify-around p-1">
          <p class="mr-2 cursor-pointer" @click="editWidget">
            <icon-hawk-settings-icon />
          </p>

          <p class="ml-2 cursor-pointer" @click="deleteWidget">
            <icon-hawk-trash-three class="w-5 h-5" />
          </p>
        </div>
        <div
          v-else-if="has_refresh_button"
          v-tippy="{ content: 'Reload' }"
          :class="{ 'invisible group-hover:visible': (props.canModifyResource && props.config.data.type !== 'single_form_view') }"
        >
          <p class="mx-2 cursor-pointer" @click="refreshWidget">
            <icon-hawk-refresh-ccw-one />
          </p>
        </div>
      </template>
    </component>
    <div v-else>
      &nbsp;
    </div>
  </component>
</template>

<style lang="scss">
.vue-grid-item>.vue-resizable-handle
{
visibility: hidden;
  bottom: 12px;
  right: 8px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%231570EFFF' stroke-width='6' stroke-dasharray='24' stroke-dashoffset='74' stroke-linecap='round'/%3e%3c/svg%3e")
}

.vue-grid-item:hover {
    .vue-resizable-handle {visibility: visible;}
}

.editing-border:hover{
   background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%231570EFFF' stroke-width='3' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
}

.widget-header {
  @apply flex justify-between items-center p-3 h-12;
}
</style>
