import { setNumberDecimals } from '~/dashboard/print/utilities.js';

function createColumnGroups(columns = []) {
  if (!columns?.length)
    return [];

  let current_group = 0;
  let current_group_length = 0;
  const first_col = {
    id: columns[0].id,
    header: columns[0].header,
  };

  // we group them by 7, the maximum columns we can display in pdf
  // we display each group in a separate table
  const column_groups = columns.reduce((result, col) => {
    // if we filled this group, create a new one
    if (current_group_length === 7) {
      current_group += 1;
      result[current_group] = [first_col];
      current_group_length = 1;
    }

    // if there is only one column or if all subcolumns fit in the same group
    if (!col.columns?.length || (current_group_length + col.columns.length <= 7)) {
      result[current_group].push(col);
      current_group_length += col.columns?.length || 1;
    }
    else {
      let remaining_columns = col.columns.length;
      // keep adding sub columns in groups by 7
      while (remaining_columns > 0) {
        // if we filled this group, create a new one
        if (current_group_length === 7) {
          current_group += 1;
          result[current_group] = [first_col];
          current_group_length = 1;
        }

        const fitted_columns = 7 - current_group_length;
        const copy_column = {
          ...col,
          columns: col.columns.slice(0, fitted_columns),
        };

        result[current_group].push(copy_column);
        current_group_length += fitted_columns;

        // remove used columns for the next line
        col.columns = col.columns.slice(fitted_columns);
        remaining_columns -= fitted_columns;
      }
    }

    return result;
  }, [[]]);

  return column_groups;
}

function createTableHeader(columns = [], one_group_table = false) {
  // table head
  const table_head = document.createElement('thead');
  // only needed when there are subcols, using table_head_row for the parent labels
  const table_head_row = document.createElement('tr');
  const table_sub_head_row = document.createElement('tr');
  const has_subcols = columns.find(c => c.columns?.length > 0);

  const first_cell_class = one_group_table && columns.length < 7 ? 'first-cell--big' : 'first-cell';

  columns.forEach((col, i) => {
    const th = document.createElement('th');
    th.innerHTML = col.header;
    if (!col.columns?.length) {
      if (has_subcols)
        th.setAttribute('rowspan', 2);

      if (i === 0)
        th.classList.add(first_cell_class);
      table_head_row.appendChild(th);
    }
    else {
      th.setAttribute('colspan', col.columns.length);
      table_head_row.appendChild(th);

      col.columns.forEach((sub_col) => {
        const child_th = document.createElement('th');
        child_th.innerHTML = sub_col.header;
        table_sub_head_row.appendChild(child_th);
      });
    }
  });

  table_head.appendChild(table_head_row);
  if (has_subcols)
    table_head.appendChild(table_sub_head_row);

  return table_head;
}

function getTableBodyCellData(data, col_id) {
  let cell_data = '-';
  if (typeof data[col_id] !== 'undefined')
    cell_data = data[col_id];

  if (typeof cell_data === 'number')
    cell_data = setNumberDecimals(cell_data, 1);
  return cell_data;
};

function createTableBody(columns, dataset = [], one_group_table = false) {
  const table_body = document.createElement('tbody');

  const flat_columns = columns.reduce((result, col) => {
    if (!col.columns?.length)
      result.push(col);
    else
      result.push(...col.columns);
    return result;
  }, []);

  const available_data = dataset.filter(d => !d.is_placeholder);

  available_data.forEach((data) => {
    const data_row = document.createElement('tr');
    const first_cell_class = one_group_table && flat_columns.length < 7 ? 'first-cell--big' : 'first-cell';

    flat_columns.forEach((col, i) => {
      const td = document.createElement('td');

      td.innerHTML = getTableBodyCellData(data, col.id);
      if (i === 0)
        td.classList.add(first_cell_class);
      data_row.appendChild(td);
    });
    table_body.appendChild(data_row);
  });

  if (!dataset?.length) {
    const data_row = document.createElement('tr');
    const td = document.createElement('td');
    td.innerHTML = 'No data present';
    td.style.textAlign = 'center';
    data_row.appendChild(td);
    table_body.appendChild(data_row);
  }

  return table_body;
}

function createTables(column_groups = [], dataset = [], table_type = '') {
  // a table widget that only has one table inside
  const one_group_table = column_groups.length === 1;

  // based on the group of columns (split by groups with max length of 7)
  // create tables and add them to the list
  const tables_array = column_groups.reduce((result, group) => {
    const table = document.createElement('table');

    if (table_type === 'list')
      table.classList.add('table--list');

    if (column_groups.length <= 5)
      table.classList.add('table--fill-width');

    const table_head = createTableHeader(group, one_group_table);
    const table_body = createTableBody(group, dataset, one_group_table);

    table.appendChild(table_head);
    table.appendChild(table_body);

    result.push(table);
    return result;
  }, []);

  return tables_array;
}

function createTableContainer(tables, chart_name, extra_rows = 0, chart) {
  // create dashboard row container
  const table_container = document.createElement('div');
  table_container.classList.add('dashboard-row');
  table_container.classList.add('dashboard-row--page-break');
  const dashboard_chart = document.createElement('div');
  dashboard_chart.classList.add('dashboard-row__chart');
  dashboard_chart.classList.add('dashboard-row__chart--no-bg');
  // add title
  const title = document.createElement('h3');
  title.classList.add('dashboard-row__name');
  title.innerHTML = `${chart_name} ${chart.dashboard_selected_range ? `| ${chart.dashboard_selected_range?.start}- ${chart.dashboard_selected_range?.end}` : ''}`;
  dashboard_chart.appendChild(title);

  // if there are too many columns, multiple tables are created
  tables.forEach((table) => {
    dashboard_chart.appendChild(table);
  });

  if (extra_rows > 0) {
    const extra_row_text = document.createElement('div');
    extra_row_text.classList.add('table-list-more');
    extra_row_text.innerHTML = `+${extra_rows} more`;
    dashboard_chart.appendChild(extra_row_text);
  }

  table_container.appendChild(dashboard_chart);

  return table_container;
}

function createTableWidget(chart) {
  const columns_groups = createColumnGroups(chart.dataSource.columns);
  const dataset = chart.dataSource.dataset;

  const tables = createTables(columns_groups, dataset);

  // used in tasks list and forms list
  let extra_rows = 0;
  if (chart.dataSource.total)
    extra_rows = chart.dataSource.total - 10;

  const table_container = createTableContainer(tables, chart.chart_name, extra_rows, chart);

  return table_container;
}

export {
  createColumnGroups,
  createTableContainer,
  createTables,
  createTableWidget,
};
