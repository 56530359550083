<script setup>
import { isEqual } from 'lodash-es';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import TransmittalList from '~/dms/components/transmittals/list/transmittals-list.vue';
import { useTransmittalsStore } from '~/dms/store/transmittals.store.js';
import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  query: {
    type: Object,
    default: () => {},
  },
  activeSelectedRange: {
    type: Array,
    default: () => [],
  },
});

const dashboard_selected_range = inject('dashboardSelectedRange');

const transmittals_store = useTransmittalsStore(`transmittal-${props.id}`);

const { getFormattedDate } = useFamConstants();
const is_loading = ref(false);
const dashboard_store = useDashboardStore();
const form_widget_header = ref(null);

const common_store = useCommonStore();

const transmittal_table = ref(null);

function updatePrintMap() {
  const print_dataset = transmittals_store.transmittals.map((f) => {
    return {
      ...f,
      status: transmittals_store.transmittal_status(f)?.label,
      category: common_store.get_category(f.category)?.name,
    };
  });
  dashboard_store.update_print_map(props.id, {
    type: props.data.data.type,
    renderAt: `chart-container-${props?.id}`,
    renderType: 'table',
    width: '100%',
    height: '100%',
    dashboard_selected_range,
    dataFormat: 'json',
    chart_name: props.data.data.name,
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      columns: transmittal_table?.value?.columns,
      activities: print_dataset,
      dashboard_index: props.data.i,
      dataset: print_dataset,

    },
  });
}

async function getData(e) {
  try {
    is_loading.value = !e;
    const query = {
      page_number: e?.pagination_state?.pageIndex || 1,
      page_size: 25,
      all: true,
      ...props.query,
    };
    await transmittals_store.set_transmittals({
      query,
      body: {
        filters: props.data?.data?.filters?.filters?.map((f) => {
          if (f.operator_option)
            return { ...f, value: getFormattedDate(f.operator_option) };
          return f;
        }),
      },
      attribute: 'filters',
      method: 'post',
    }, !!e);
    is_loading.value = false;
  }
  catch (err) {
    logger.error(err);
  }
}

onMounted(() => {
  getData();
});

if (props.id === 'preview') {
  watch(() => props.data, (new_val, old_val) => {
    if (new_val && !isEqual(new_val, old_val)) {
      setTimeout(() => {
        getData();
      }, 250);
    }
  }, { immediate: true, deep: true });
}

if (props.id !== 'preview') {
  watch(() => props.data.data, (new_val, old_val) => {
    if (new_val && !isEqual(new_val, old_val)) {
      setTimeout(() => {
        getData();
      }, 250);
    }
  }, { immediate: true, deep: true });
}

watch(() => props.activeSelectedRange, (new_val, old_val) => {
  if (!isEqual(new_val, old_val) && (props?.id !== 'preview')) {
    getData();
  }
});
</script>

<template>
  <div>
    <div ref="form_widget_header">
      <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
        <slot name="header-title" />
        <slot name="header-actions" />
      </div>
    </div>
    <div v-if="is_loading">
      <hawk-loader />
    </div>
    <TransmittalList
      v-else
      ref="transmittal_table"
      :store_key="`transmittal-${props.id}`"
      :transmittals="transmittals_store.transmittals"
      @load-more="getData($event)"
      @table-instance-created="updatePrintMap()"
    />
  </div>
</template>
