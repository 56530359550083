<script setup>
import dayjs from 'dayjs';
import { useModal } from 'vue-final-modal';
import HawkInfoIcon from '~/common/components/atoms/hawk-info-icon.vue';
import DocumentViewerWrapper from '~/dms/components/atoms/document-viewer-wrapper.vue';

import DmsDocumentsRequestForm from '~/dms/components/documents/forms/dms-documents-request-form.vue';
import { get_transmittal_status } from '~/dms/constants';
import { useTransmittalsStore } from '~/dms/store/transmittals.store';

const props = defineProps({
  reviews: {
    type: Array,
    default: () => ([]),
  },
  title: {
    type: String,
    default: null,
  },
  feedback: {
    type: Object,
    default: null,
  },
  document: {
    type: Object,
    default: null,
  },
});

const $t = inject('$t');
const transmittal_store = useTransmittalsStore();
const route = useRoute();
const show_viewer = ref(null);
const { TRANSMITTAL_STATUS } = get_transmittal_status();

const document_unlock_modal = useModal({
  component: DmsDocumentsRequestForm,
});
const due_date = props.feedback?.due_date;
const due_date_offset = due_date ? dayjs(due_date).diff(new Date(), 'Day') : 0;

const document_status_map = computed(() => transmittal_store.transmittal?.review_status_set);

const is_completed = (props.reviews || []).filter(review => !review.is_completed)?.length === 0;
function openUnlockForm(review) {
  document_unlock_modal.patchOptions({
    attrs: {
      header_icon: HawkInfoIcon,
      header: $t('Open/Unlock response'),
      button_text: $t('Unlock'),
      content: $t('Are you sure you want to open this request again? If yes, please add your reason for opening the request.'),
      confirm: async (data) => {
        const payload = {
          transmittal_uid: route.params.transmittal_uid,
          transmittals: {
            documents: [{
              ...data,
              uid: props.document?.uid,
              reviewer: review.user,
            }],
          },
        };
        await transmittal_store.document_operations(payload, 'unlock');
        document_unlock_modal.close();
      },
      onClose() {
        document_unlock_modal.close();
      },
    },
  });
  document_unlock_modal.open();
}

async function downloadFile(review) {
  if (review?.attachment?.presigned_url)
    window.open(review?.attachment?.presigned_url, '_blank');
}

function reviewStatusBadgeClasses(color) {
  const class_map = {
    orange: 'bg-orange-50 text-orange-700',
    lightgreen: 'bg-emerald-50 text-emerald-700',
  };
  return class_map[color];
}

const get_status_title = status => document_status_map.value?.[status] || TRANSMITTAL_STATUS[status]?.title;
</script>

<template>
  <div class="border rounded-lg my-3" :class="{ 'bg-gray-50 opacity-70 cursor-not-allowed': props.feedback.status === 'not_started' }">
    <div class="flex justify-between w-full  p-3 border-b">
      <span class="text-sm text-gray-500 font-semibold">
        {{ title }}
      </span>
      <div class="flex items-center">
        <span v-if="due_date" class="text-xs mr-2">
          <span>{{ $t('Due date') }}</span>:
          <span v-if="is_completed || due_date_offset > 7" class="font-medium">{{ $date_relative(due_date) }}</span>
          <span v-else-if="due_date_offset > 0" class="font-medium text-warning-500"> {{ $date_relative(due_date) }}</span>
          <span v-else class="font-medium text-red-500">{{ $date_relative(due_date) }}</span>
        </span>
        <div v-if="feedback.name" v-tippy="{ content: feedback.final_outcome ? `Final outcome: ${get_status_title(feedback.final_outcome)}` : null }">
          <hawk-badge :color="TRANSMITTAL_STATUS[feedback.outcome || 'pending']?.color" :custom_classes="reviewStatusBadgeClasses(TRANSMITTAL_STATUS[feedback.outcome || 'pending']?.color)" class="mr-1 whitespace-nowrap">
            <IconHawkInfoCircle v-if="feedback.final_outcome" class="w-4 h-4" />
            {{ feedback.outcome ? get_status_title(feedback.outcome) : TRANSMITTAL_STATUS.pending.title }}
          </hawk-badge>
        </div>
      </div>
    </div>
    <div v-if="props.feedback.status !== 'not_started'">
      <div
        v-for="(review, index) in reviews"
        :key="index"
        class="p-3 border-b last:border-b-0"
      >
        <div class="flex justify-between group/item">
          <div class="flex items-center justify-between">
            <span class="text-sm font-semibold text-gray-700 mr-4">
              <HawkMembers :members="review.user" type="label" :has_avatar="false" />
            </span>
            <hawk-badge v-if="review?.annotations_count" color="blue">
              <IconHawkPenToolTwo class="w-[12px] h-[12px]" />
              {{ review?.annotations_count }}
            </hawk-badge>
            <div class="cursor-pointer">
              <IconHawkLockUnlockedOne
                v-if="review?.can_unlock"
                class="invisible w-5 h-5 text-gray-700 group-hover/item:visible"
                @click="() => openUnlockForm(review)"
              />
            </div>
          </div>
          <div class="flex items-center">
            <hawk-badge :color="TRANSMITTAL_STATUS[review?.status || 'pending_review']?.color" :custom_classes="reviewStatusBadgeClasses(TRANSMITTAL_STATUS[review?.status || 'pending_review']?.color)" class="mr-1">
              {{ get_status_title(review?.status || 'pending_review') }}
            </hawk-badge>
          </div>
        </div>
        <div class="flex w-full justify-between">
          <div class="text-gray-900 font-medium text-xs w-[80%]">
            {{ review?.comments || '' }}
          </div>
        </div>
        <div v-if="review?.attachment?.file_name" class="flex text-xs items-center gap-2 mt-1">
          <div class="!text-gray-900">
            {{ review?.attachment?.file_name }}
          </div>
          <span class="text-gray-700">{{ review?.attachment?.file_size ? $prettysize(review?.attachment.file_size) : '' }}</span>
          <DocumentViewerWrapper
            v-slot="{ is_attachment_supported }"
            :document="review?.attachment"
            :show_viewer="show_viewer === index"
            @close-attachment="show_viewer = null"
          >
            <span v-if="is_attachment_supported" class="text-gray-600 cursor-pointer" @click="show_viewer = index"><IconHawkEye class="w-4" /></span>
          </DocumentViewerWrapper>
          <span v-if="review?.attachment?.presigned_url" class="text-gray-600 cursor-pointer" @click="downloadFile(review)"><IconHawkDownloadTwo class="w-4 h-4" /></span>
        </div>
      </div>
    </div>
  </div>
</template>
