import { $date } from '~/common/utils/date.util';
import { createTableContainer, createTables } from '~/dashboard/print/charts/table';
import { useTasksStore } from '~/tasks/store/tasks.store';

function getTaskStatus(id) {
  const tasks_store = useTasksStore();
  return tasks_store.status_map[id]?.label;
}

function getTaskPriority(id) {
  const tasks_store = useTasksStore();
  return tasks_store.priority_map[id]?.label;
}

function createTaskList(chart, _index) {
  const columns = [
    [
      {
        id: 'name',
        header: 'Name',
      },
      {
        id: 'priority',
        header: 'Priority',
      },
      {
        id: 'due_date',
        header: 'Due Date',
      },
      {
        id: 'status',
        header: 'Status',
      },
    ],
  ];

  let dataset = [];
  dataset = chart.dataSource.dataset.map((d) => {
    const due_date = d.due_date
      ? $date(d.due_date, 'DD MMMM YYYY')
      : '-';
    return {
      name: d.name,
      priority: getTaskPriority(d.priority),
      due_date,
      status: getTaskStatus(d.status),
    };
  });

  const tables = createTables(columns, dataset, 'list');

  const table_container = createTableContainer(
    tables,
    chart.chart_name,
    chart.dataSource.total - 10,
    chart,
  );

  return table_container;
}

export {
  createTaskList,
};
