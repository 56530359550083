<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useElementSize, useWindowSize } from '@vueuse/core';
import { defineAsyncComponent } from 'vue';
import HawkLoader2 from '~/common/components/atoms/hawk-loader-2.vue';
import { useTasksStore } from '~/tasks/store/tasks.store.js';

const props = defineProps({
  count: {
    type: Number,
    default: 0,
  },
  payload: {
    type: Object,
    default: () => {},
  },
  popup_query: {
    type: Object,
    default: () => {},
  },
  name: {
    type: String,
    default: 'Untitled',
  },
  id: {},
});

const emit = defineEmits(['close']);

const TasksView = defineAsyncComponent({
  loader: () => import('~/tasks/pages/tasks/tasks-view.vue'),
  loadingComponent: HawkLoader2,
});

const $t = inject('$t');

const task_store = useTasksStore();
const task_modal_header = ref(null);
const { height } = useWindowSize();
const header_properties = useElementSize(task_modal_header);

const table_height = computed(() => {
  return (height.value * 0.8) - (header_properties.height.value > 50 ? header_properties.height.value + 96 : header_properties.height.value + 108);
});

const header_note = computed(() => {
  if (task_store.total_task_count && props.count && Number.parseInt(task_store.total_task_count) !== props.count)
    return `${$t('You have access to only')} ${task_store.total_task_count} ${$t('out of')} ${props.count} ${$t('tasks')}.`;
  return '';
});
</script>

<template>
  <HawkModalTemplate :disable_footer="true" content_class="h-[80vh] rounded-lg w-[50vw]" @close="emit('close')">
    <template #header_left>
      <div ref="task_modal_header" class="flex flex-col justify-start">
        {{ props.name }}
      </div>
    </template>
    <template #default>
      <div>
        <div v-if="header_note" class="font-regular text-sm mb-2">
          <span class="font-semibold text-sm">
            {{ $t('Note') }}:
          </span>
          {{ header_note }}
        </div>
        <TasksView
          :id="id"
          :table_options="{
            column_config: { name: {}, priority: {}, due_date: {}, status: {} },
            table_height,
            no_table_wrapper: true,
          }"
          :is_compact_view="true"
          :is_modal_view="true"
          class="scrollbar"
          :show_context_menu="false"
          store_key="task-modal"
          :options="
            {
              disable_subtask_create: true,
              hide_subtasks: true,
              set_pusher: false,
              hide_breadcrumbs: true,
              include_subtasks: payload?.filters?.sub_tasks,
              query: {
                ...payload?.filters,
                sub_tasks: payload?.filters?.sub_tasks,
                scope: payload?.scope,
                ...popup_query,
                breadcrumbs: true,
                set_pusher: false,
              },
            }"
          :style="`height:${table_height}px`"
          :is_widget="true"
        >
          <template #top-panel>
            <div />
          </template>
          <template v-if="payload.filters.sub_tasks" #task_name="widget_data">
            <div>
              <div v-if="widget_data?.task?.element?.breadcrumbs?.length" class="flex items-center justify-center mb-1">
                <hawk-breadcrumbs
                  :items="widget_data.task.element.breadcrumbs.map(b => ({ uid: b.uid, label: b.name }))"
                  :show_active_color="false"
                />
                <IconHawkChevronRight aria-hidden="true" class="w-[16px] h-[16px] text-gray-300" />
                <HawkText class="font-medium text-gray-500 text-sm" :content="$t('tasks')" />
              </div>
              <div class="text-sm font-medium text-gray-900 max-w-[350px] truncate">
                {{ widget_data.task.element.name }}
              </div>
            </div>
          </template>
        </TasksView>
      </div>
    </template>
  </HawkModalTemplate>
</template>
