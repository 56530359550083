<script setup>
import { useElementSize } from '@vueuse/core';
import { debounce, isEqual, pick } from 'lodash-es';

import { storeToRefs } from 'pinia';
import { inject, provide, ref, watch } from 'vue';
import { useModal } from 'vue-final-modal';
import DashboardChartLabelsModal from '~/dashboard/components/dashboard-chart-labels-modal.vue';
import Widgets from '~/dashboard/components/widgets/widget.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

const emit = defineEmits(['close']);
const $t = inject('$t');

const dashboard_store = useDashboardStore();
const previewing_widget = ref(null);
const { height } = useElementSize(previewing_widget);

const {
  widget_configuration,
  widget_name,
  is_form_valid,
  is_editing_widget,
  current_dashboard,
} = storeToRefs(dashboard_store);

const widget = ref({});
const widget_component = ref(null);
const has_preview = ref(false);
const preview_loading = ref(false);
const changes_popup = ref(false);

async function enablePreview() {
  if (!is_form_valid.value)
    return;

  widget.value = { ...widget_configuration.value };
  changes_popup.value = false;
  has_preview.value = true;
}

const debouncedEnablePreview = debounce(() => {
  enablePreview();
}, 750);

async function save() {
  if (!is_form_valid.value)
    return;

  widget.value = { ...widget_configuration.value };
  if (dashboard_store.scope === 'inventory') {
    if (is_editing_widget.value) {
      dashboard_store.update_widget(widget.value, true, $t);
      await dashboard_store.update_dashboard({ ...current_dashboard.value, name: widget_name.value }, $t);
    }
    else {
      dashboard_store.create_report_dashboard(widget.value);
    }
  }
  else if (is_editing_widget.value) {
    dashboard_store.update_widget(widget.value, true, $t);
  }
  else {
    dashboard_store.add_widget(widget.value);
  }

  emit('close');
}

function updateWidgetPreview() {
  if (widget_configuration.value?.type === 'map-view') {
    enablePreview();
    setTimeout(async () => {
      await widget_component.value.fetchMapViewData();
      enablePreview();
    }, 150);
  }
  else { debouncedEnablePreview(); }
}

const chart_labels_modal = useModal({
  component: DashboardChartLabelsModal,
  attrs: {
    onClose() {
      chart_labels_modal.close();
    },
  },
});

const can_config_labels = computed(() => {
  if ([
    'activities_work_combined_table',
  ].includes(widget?.value?.type) && widget?.value.activity_table_chart) {
    return true;
  }
  else if (widget?.value?.type === 'feature_type_counts' && ['vertical_graph', 'horizontal_bar', 'donut'].includes(widget?.value?.chart_type)) {
    return true;
  }
  else if (widget?.value?.type) {
    return [
      'vertical_graph',
      'horizontal_bar',
      'donut',
      'area',
      'status_breakdown',
      'assignee_breakdown',
      'asset_breakdown',
      'step_history',
      'line',
      'defects_by_inverter',
      'temperature_histogram',
      'severity_graph',
      // 'feature_type_counts',
      'pivot table',
      'planned_vs_actual',
      'progress_history',
      'activity_history_table',
      'activities_breakdown',
    ].includes(widget.value.type);
  }
  else {
    return false;
  }
});
const remove_overflow_hidden = computed(() => {
  return ['asset_pivot_table', 'terra_pivot_table'].includes(widget.value.type) ? 'overflow-inherit' : '';
});
watch(widget_configuration, (old_widget_configuration, new_widget_configuration) => {
  if (widget_configuration.value?.type !== 'map-view' && is_form_valid.value) {
    debouncedEnablePreview();
  }
  else {
    const cherry_picked_old_config = pick(old_widget_configuration, ['container', 'feature_types', 'projects', 'type']);
    const cherry_picked_new_config = pick(new_widget_configuration, ['container', 'feature_types', 'projects', 'type']);
    if (!isEqual(cherry_picked_old_config, cherry_picked_new_config))
      changes_popup.value = true;
  }
});

watch(is_form_valid, (val) => {
  if (!val && widget_configuration.value?.type !== 'map-view')
    changes_popup.value = true;
});

onMounted(() => {
  if (is_editing_widget.value && is_form_valid)
    debouncedEnablePreview();
});
provide('is_loading_pdf', false);
</script>

<template>
  <div class="flex flex-col px-4 pt-4" :class="remove_overflow_hidden">
    <div class="flex justify-between">
      <div class="text-lg font-semibold text-gray-900 capitalize">
        {{ $t('Preview') }}
      </div>
      <hawk-button v-if="can_config_labels" icon type="outlined" @click="chart_labels_modal.open()">
        <IconHawkSettingsOne class="w-5 h-5" />
      </hawk-button>
    </div>

    <div v-if="!has_preview" class="flex flex-col h-full w-full items-center justify-center">
      <div class="w-[524px] bg-white border border-solid border-gray-200 rounded-lg flex flex-col items-center p-4 pt-3">
        <IconHawkDashboardPreviewInfo class="mb-2 w-10 h-10" />
        <div class="text-sm font-semibold text-gray-900 mb-1">
          {{ $t('Setup report') }}
        </div>
        <div class="text-sm text-gray-600 mb-4">
          {{ $t('Configure options for the report and click Preview') }}
        </div>
        <hawk-button
          :loading="preview_loading"
          :disabled="!is_form_valid"
          @click="debouncedEnablePreview"
        >
          <IconHawkEye class="text-white" />
          <span class="text-sm font-medium">
            {{ $t('Preview') }}
          </span>
        </hawk-button>
      </div>
    </div>

    <div v-else-if="has_preview" class="h-full relative">
      <div
        v-if="changes_popup"
        class="absolute h-full w-full top-0 left-0 z-20 bg-white flex items-center justify-center"
      >
        <div class="relative w-[524px] bg-white border border-solid border-gray-200 rounded-lg flex flex-col items-center p-4 pt-3">
          <IconHawkDashboardPreviewWarning class="mb-2 w-10 h-10" />
          <div class="text-sm font-semibold text-gray-900 mb-1">
            {{ $t('Changes detected') }}
          </div>
          <div class="text-sm text-gray-600 mb-4">
            {{ $t('New changes is been detected. You can reload to view new changes.') }}
          </div>
          <hawk-button :loading="preview_loading" :disabled="!is_form_valid" @click="updateWidgetPreview">
            <IconHawkRefreshCwTwo />
            <span class="text-sm font-medium">
              {{ $t('Reload Preview') }}
            </span>
          </hawk-button>
        </div>
      </div>
      <div id="hawk-dashboard" ref="previewing_widget" class="h-3/4 mb-8">
        <Widgets
          v-if="is_form_valid && !changes_popup"
          ref="widget_component"
          :config="{ data: widget }"
          :is-previewing="true"
          :previewing-content-height="height"
          :class="remove_overflow_hidden"
        />
      </div>
      <div class="flex justify-end gap-3">
        <hawk-button type="outlined" @click="emit('close')">
          {{ $t('Cancel') }}
        </hawk-button>
        <hawk-button
          :disabled="!is_form_valid"
          @click="save"
        >
          {{ $t('Save') }}
        </hawk-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.overflow-inherit {
  overflow: inherit !important;
}
</style>
