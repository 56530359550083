<script setup>
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import { isNil } from 'lodash-es';
import { useCustomFields } from './custom-fields.composable.js';

const props = defineProps({
  field: {
    type: Object,
    default: null,
  },
  value: {
    default: null,
  },
  collection: {
    type: String,
  },
});

const { custom_fields_collection, Types: custom_fields_types } = useCustomFields();

const options_map = {};
if (props.collection && custom_fields_collection[props.collection])
  custom_fields_collection[props.collection].forEach(field => options_map[field.value] = field.options);

function formatted_value() {
  let result = '';
  if (!props.value)
    return '-';

  switch (props.field?.type) {
    case custom_fields_types.CHECKBOX.value:
    case custom_fields_types.CHECKBOXES.value:
      result = props.value.map(value =>
        props.field.items.find(item =>
          item.value === value)?.label || 'NA')
        .join(', ') || '-';
      break;
    case custom_fields_types.RADIO.value:
    case custom_fields_types.DROPDOWN.value:
      result = props.field.items.find(item =>
        item.value === props.value)?.label || 'NA';
      break;
    case custom_fields_types.MEMBER.value:
      result = props.value;
      break;
    case custom_fields_types.MEMBERS.value:
      result = props.value;
      break;
    case custom_fields_types.DATE.value:
      result = dayjs(props.value).format('D MMMM, YYYY');
      break;
    default:
      result = props.value;
  }
  return result;
}

function getBadgeData(value) {
  if (Array.isArray(value)) {
    return value.map(val =>
      props.field.items.find(item =>
        item.value === val));
  }

  else if (value) {
    return [props.field.items.find(item =>
      item.value === value)];
  }
}
</script>

<template>
  <div>
    <div v-if="isNil(value)">
      -
    </div>
    <template v-else-if="field.type === custom_fields_types.FILE.value">
      <div v-if="value">
        {{ value.length }} {{ $t("Attachments") }}
      </div>
      <div v-else>
        -
      </div>
    </template>
    <template v-else-if="field.type === custom_fields_types.SIGNATURE.value">
      <img v-if="value" class="max-h-[40px]" :src="value?.location ? value.location : value" alt="">
      <div v-else>
        -
      </div>
    </template>
    <template v-else-if="field.type === custom_fields_types.YES_NO_NA.value">
      <hawk-checkbox-tristate :model-value="value" disabled />
    </template>
    <template v-else-if="field.type === custom_fields_types.MEMBER.value">
      <HawkMembers :members="value" type="label" :has_avatar="false" />
    </template>
    <template v-else-if="field.type === custom_fields_types.MEMBERS.value">
      <HawkMembers :members="value" v-bind="options_map[field.type] || {}" />
    </template>
    <template v-else-if="field.type === custom_fields_types.SINGLE_SELECT.value">
      <div v-if="value">
        <HawkBadge v-for="badge in getBadgeData(value)" :key="badge" :custom_color="badge?.color">
          {{ badge?.label }}
        </HawkBadge>
      </div>
      <div v-else>
        -
      </div>
    </template>
    <template v-else-if="field.type === custom_fields_types.MULTI_SELECT.value">
      <div v-if="value && value.length > 0">
        <HawkBadge v-for="badge in getBadgeData(value)" :key="badge" class="mr-2" :custom_color="badge?.color">
          {{ badge?.label }}
        </HawkBadge>
      </div>
      <div v-else>
        -
      </div>
    </template>
    <template v-else-if="field.type === custom_fields_types.URL.value">
      <a v-if="value" :href="DOMPurify.sanitize(value)" target="_blank" rel="noopener">
        {{ value }}
      </a>
      <div v-else>
        -
      </div>
    </template>
    <template v-else-if="field.type">
      {{ formatted_value() }}
    </template>
  </div>
</template>
