<script setup>
import { capitalize } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';

import { useAuthStore } from '~/auth/stores/auth.store';
import DashboardPreview from '~/dashboard/components/dashboard-preview.vue';
import DashboardWidgetAsset from '~/dashboard/components/dashboard-widget-asset.vue';
import DashboardWidgetName from '~/dashboard/components/dashboard-widget-name.vue';
import DashboardAssetForm from '~/dashboard/pages/form-types/dashboard-asset-form.vue';
import DashboardCustomForm from '~/dashboard/pages/form-types/dashboard-custom-form.vue';
import DashboardDmsForm from '~/dashboard/pages/form-types/dashboard-dms-form.vue';
import DashboardFormsForm from '~/dashboard/pages/form-types/dashboard-forms-form.vue';
import DashboardInventoryForm from '~/dashboard/pages/form-types/dashboard-inventory-form.vue';
import DashboardScheduleForm from '~/dashboard/pages/form-types/dashboard-schedule-form.vue';
import DashboardTasksForm from '~/dashboard/pages/form-types/dashboard-tasks-form.vue';
import DashboardTerraForm from '~/dashboard/pages/form-types/dashboard-terra-form.vue';
import DashboardThermForm from '~/dashboard/pages/form-types/dashboard-therm-form.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

const props = defineProps({
  breadcrumbs: {
    type: Array,
  },
  // eslint-disable-next-line vue/prop-name-casing
  widget_label: {
    type: String,
  },
});

const emit = defineEmits(['close']);
const dashboard_store = useDashboardStore();
const auth_store = useAuthStore();

const {
  widget_configuration,
  scope,
} = storeToRefs(dashboard_store);

const $t = inject('$t');
const route = useRoute();

const can_reset = ref(false);

const active_widget_type = ref(null);

const widget_types = computed(() => {
  let types = [];
  if (scope.value === 'schedule') {
    types = ['schedule'];
  }
  else if (scope.value === 'forms') {
    types = ['forms'];
  }
  else if (scope.value === 'inventory') {
    types = ['inventory'];
  }
  else {
    types = [
      ...(scope.value === 'organization' ? ['assets'] : []),
      'tasks',
      'forms',
      'terra',
      'therm',
      'schedule',
      ...(scope.value !== 'organization' ? ['DMS'] : []),
      'custom',
    ];
  }

  return types.map((key) => {
    return {
      uid: key,
      label: key === 'DMS' ? $t('DMS') : $t(capitalize(key)),
      on_click: () => {
        active_widget_type.value = key;
        can_reset.value = true;
      },
    };
  });
});

const active_form = computed(() => {
  if (scope.value === 'schedule') {
    return DashboardScheduleForm;
  }
  else if (scope.value === 'forms') {
    return DashboardFormsForm;
  }
  else if (scope.value === 'inventory') {
    return DashboardInventoryForm;
  }
  else {
    let form_value = '';
    switch (active_widget_type.value) {
      case 'assets':
        form_value = DashboardAssetForm;
        break;
      case 'tasks':
        form_value = DashboardTasksForm;
        break;
      case 'forms':
        form_value = DashboardFormsForm;
        break;
      case 'terra':
        form_value = DashboardTerraForm;
        break;
      case 'therm':
        form_value = DashboardThermForm;
        break;
      case 'schedule':
        form_value = DashboardScheduleForm;
        break;
      case 'DMS':
        form_value = DashboardDmsForm;
        break;
      case 'custom':
        form_value = DashboardCustomForm;
        break;
      default:
        form_value = DashboardTasksForm;
        break;
    }
    return form_value;
  }
});

function setWidgetType() {
  const widget_module = widget_configuration.value?.module === 'dms' ? 'DMS' : widget_configuration.value?.module;
  if (['schedule', 'forms', 'inventory'].includes(scope.value))
    active_widget_type.value = scope.value;
  else if (auth_store.check_split('asset_widgets') && ['organization'].includes(scope.value))
    active_widget_type.value = widget_module || 'assets';
  else
    active_widget_type.value = widget_module || 'tasks';
}

function setWidgetAsset() {
  if (route.params.asset_id)
    dashboard_store.set_widget_asset(route.params.asset_id);
}

function handleBrowserBack() {
  close();
  history.go(1);
}

onMounted(() => {
  window.addEventListener('popstate', handleBrowserBack);
  setWidgetType();
  setWidgetAsset();
});

onBeforeUnmount(() => {
  window.removeEventListener('popstate', handleBrowserBack);
  dashboard_store.set_default_values();
});

function close() {
  can_reset.value = true;
  setTimeout(() => {
    emit('close');
  }, 100);
}
</script>

<template>
  <HawkModalTemplate content_class="h-screen w-screen" :disable_footer="true" @close="close()">
    <template #header>
      <div>
        <div class="py-4 mx-4 border-b border-solid border-gray-200 h-[60px] flex justify-between">
          <HawkBreadcrumbs
            :items="breadcrumbs || [
              { uid: '1', label: $t('Dashboard'), on_click: () => close() },
              { uid: '2', label: $t('Add Widget') },
            ]"
          />
          <HawkButton icon type="text" @click="close()">
            <IconHawkX />
          </HawkButton>
        </div>
        <div class="flex mx-4 items-center border-b border-solid border-gray-200">
          <div class="p-1 ml-1 cursor-pointer" @click="close()">
            <icon-hawk-chevron-left class="w-5 h-5" />
          </div>
          <HawkPageHeader :title="$t(`${props.widget_label || 'Add Widget'}`)">
            <template v-if="widget_types.length > 1" #left>
              <HawkPageHeaderTabs :tabs="widget_types" :active_item="active_widget_type" @tab-click="showTabs()" />
            </template>
          </HawkPageHeader>
        </div>
      </div>
    </template>
    <template #default>
      <div class="w-full flex dashboard-content-height">
        <div class="-ml-4 flex relative flex-col border-r border-solid border-gray-200 min-w-[630px] max-w-[630px] scrollbar pl-6 pr-4">
          <div class="sticky top-0 pt-4 pb-3 mb-3 bg-white z-10">
            <div class="text-lg font-semibold text-gray-900 capitalize">
              {{ $t(capitalize(active_widget_type)) }}
            </div>
            <div class="text-xs text-gray-600">
              {{ $t('Select options and preview') }}
            </div>
          </div>
          <DashboardWidgetName />
          <DashboardWidgetAsset v-if="scope === 'organization' && active_widget_type !== 'assets'" />
          <component :is="active_form" :can-reset="can_reset" />
        </div>
        <DashboardPreview class="w-3/4 overflow-hidden" @close="close()" />
      </div>
    </template>
  </HawkModalTemplate>
</template>

<style>
.dashboard-content-height {
  height: calc(100vh - 150px)
}
</style>
