import { acceptHMRUpdate, defineStore } from 'pinia';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { getDateInterval, isDateIntervalOperator } from '~/dashboard/components/filters/composables/filters-config';
import SelectOptionTemplate from '~/dashboard/components/vueform-schema-templates/select-option-template.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

export const useDashboardInventoryStore = defineStore('dashboard_inventory', {
  state: () => {
    return {
      inventory_configuration: {},
      items_with_serial_number_tracking_enabled: [],
      data_set: false,
    };
  },
  getters: {
    type_options: () => {
      const { $t } = useCommonImports();
      return [
        ['material_tracking', 'Material tracking', 'Track information across various items (or material) for the defined columns'],
        ['stock_tracking', 'Stock tracking', 'Track stock information in a pivot table for the items that are tracked using serial numbers'],
        ['transactions_pivot', 'Transaction items', 'Track items within transactions with a pivot table'],
        ['adjustment', 'Stock quantities by transaction', 'View the stock quantities breakdown by their transactions in the selected time period'],
        ['transaction', 'Transactions', 'View all published transactions within the selected time range'],
        ['to_status', 'Stock quantities by status', 'View the stock quantities of various items as on a particular date'],
      ].map((item) => {
        return {
          value: item[0],
          label: $t(item[1]),
          description: $t(item[2]),
        };
      });
    },
    timerange_type_options: () => {
      const { $t } = useCommonImports();
      return [
        ['all_time', 'All time'],
        ['today', 'Today'],
        ['last_7_days', 'Last 7 days'],
        ['last_14_days', 'Last 14 days'],
        ['last_30_days', 'Last 30 days'],
        ['this_week', 'This week'],
        ['this_month', 'This month'],
        ['year_to_date', 'This year'],
      ].map(item => ({
        value: item[0],
        label: $t(item[1]),
      }));
    },
    timerange_interval_options: () => {
      const { $t } = useCommonImports();
      return [
        ['daily', 'Daily'],
        ['weekly', 'Weekly'],
        ['monthly', 'Monthly'],
      ].map(item => ({
        value: item[0],
        label: $t(item[1]),
      }));
    },
    timeline_type_options: () => {
      const { $t } = useCommonImports();
      return [
        ['today', 'Today'],
        ['yesterday', 'Yesterday'],
        ['last_7_days', 'Last 7 days'],
        ['last_30_days', 'Last 30 days'],
        ['this_week', 'This week'],
        ['last_week', 'Last week'],
        ['this_month', 'This month'],
        ['last_month', 'Last month'],
        ['this_quarter', 'This quarter'],
        ['last_quarter', 'Last quarter'],
        ['this_year', 'This year'],
        ['last_year', 'Last year'],
        ['week_to_date', 'Week to date'],
        ['month_to_date', 'Month to date'],
        ['quarter_to_date', 'Quarter to date'],
        ['year_to_date', 'Year to date'],
        ['custom', 'Custom'],
      ].map((item) => {
        return {
          value: item[0],
          label: $t(item[1]),
        };
      });
    },
    inventory_widget_type: (state) => {
      return state.inventory_configuration.type || state.type_options[0].value;
    },
    inventory_track_stock_uid: (state) => {
      return state.inventory_configuration.track_stock_uid || state.transaction_types[0].value;
    },
    warehouses: () => {
      const { warehouses } = useInventoryStore();
      return warehouses;
    },
    transaction_types: () => {
      const { workflows } = useInventoryStore();
      return workflows.map(wf => ({
        label: wf.name,
        value: wf.uid,
      }));
    },
    track_stock_field_options: (state) => {
      const { custom_fields } = useInventoryStore();
      const fields = custom_fields.filter((cf) => {
        if (cf.type !== 'date')
          return false;

        let is_item_workflow_attached = false;
        cf.attached_to.forEach((attached_to) => {
          if (attached_to.type === 'item_workflow' && attached_to.uid === state.inventory_configuration.track_stock_uid && attached_to.active)
            is_item_workflow_attached = true;
        });
        return is_item_workflow_attached;
      }).map(cf => ({
        label: cf.name,
        value: cf.uid,
      }));
      const { $t } = useCommonImports();
      return [{ label: $t('Transaction date'), value: 'date' }, ...fields];
    },
    material_tracking_schema: (state) => {
      const { $t } = useCommonImports();
      return {
        track_stock_quantities: {
          type: 'toggle',
          native: false,
          label: $t('Track stock quantities'),
          description: $t('Track day, week or month wise procured quantities for the selected transaction type'),
          default: state.inventory_configuration.track_stock_quantities || false,
        },
        ...(state.inventory_configuration.track_stock_quantities && {
          track_stock_uid: {
            type: 'select',
            items: state.transaction_types,
            search: true,
            native: false,
            label: $t('Transaction'),
            canClear: false,
            canDeselect: false,
            inputType: 'search',
            autocomplete: 'off',
            default: state.inventory_configuration.track_stock_uid || state.transaction_types[0].value,
            slots: {
              option: SelectOptionTemplate,
            },
          },
          track_stock_field: {
            type: 'select',
            items: state.track_stock_field_options,
            search: true,
            native: false,
            label: $t('Field'),
            canClear: false,
            canDeselect: false,
            inputType: 'search',
            autocomplete: 'off',
            default: state.inventory_configuration.track_stock_field || state.track_stock_field_options[0]?.value || null,
            slots: {
              option: SelectOptionTemplate,
            },
          },
          track_stock_timerange_type: {
            type: 'select',
            items: state.timerange_type_options,
            search: true,
            native: false,
            label: $t('Time range'),
            canClear: false,
            canDeselect: false,
            inputType: 'search',
            autocomplete: 'off',
            default: state.inventory_configuration.track_stock_timerange_type || state.timerange_type_options[0].value,
            slots: {
              option: SelectOptionTemplate,
            },
          },
          track_stock_timerange_interval: {
            type: 'select',
            items: state.timerange_interval_options,
            search: true,
            native: false,
            label: $t('Interval'),
            canClear: false,
            canDeselect: false,
            inputType: 'search',
            autocomplete: 'off',
            default: state.inventory_configuration.track_stock_timerange_interval || state.timerange_interval_options[0].value,
            slots: {
              option: SelectOptionTemplate,
            },
          },
        }),
        bom_enabled: {
          type: 'toggle',
          native: false,
          label: $t('Bill Of Material (BOM)'),
          description: $t('Track block-level material requirements for the selected warehouses'),
          default: state.inventory_configuration.bom_enabled || false,
        },
      };
    },
    stock_tracking_schema: (state) => {
      const { $t } = useCommonImports();
      return {
        items: {
          type: 'select',
          label: $t('Item'),
          items: state.items_with_serial_number_tracking_enabled,
          search: true,
          native: false,
          canClear: false,
          canDeselect: true,
          inputType: 'search',
          autocomplete: 'off',
          labelProp: 'name',
          valueProp: 'uid',
          object: true,
          rules: ['required'],
          default: state.items_with_serial_number_tracking_enabled[0] || null,
        },
      };
    },
    transactions_pivot_schema: (state) => {
      const { $t } = useCommonImports();
      return {
        workflows: {
          type: 'tags',
          label: $t('Transactions'),
          items: state.transaction_types,
          search: true,
          native: false,
          canClear: false,
          canDeselect: true,
          closeOnSelect: false,
          inputType: 'search',
          autocomplete: 'off',
          rules: ['required'],
          default: state.inventory_configuration.workflows || [],
        },
        timeline_type: {
          type: 'select',
          items: state.timeline_type_options,
          search: true,
          native: false,
          label: $t('Timeline'),
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          rules: ['required'],
          default: state.inventory_configuration.timeline_type || state.timeline_type_options?.[0]?.value,
        },
        ...(state.inventory_configuration.timeline_type === 'custom' && {
          timeline_range: {
            type: 'date-time',
            label: $t('Range'),
            options: {
              range: true,
              format: 'dd MMM yyyy',
              maxDate: new Date(),
            },
            autocomplete: 'off',
            rules: ['required'],
            default: state.inventory_configuration.timeline_range || [],
          },
        }),
      };
    },
    inventory_schema_1: (state) => {
      if (!state.data_set)
        return {};

      const { $t } = useCommonImports();
      const auth_store = useAuthStore();
      const inventory_store = useInventoryStore();

      return {
        type: {
          type: 'select',
          items: state.type_options,
          search: true,
          native: false,
          label: $t('Type'),
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          rules: ['required'],
          default: state.inventory_configuration.type || state.type_options[0].value,
          slots: {
            option: SelectOptionTemplate,
          },
        },
        ...(state.inventory_configuration.type === 'material_tracking' && {
          warehouses: {
            type: 'tags',
            label: $t('Warehouses'),
            items: inventory_store.warehouses.map(warehouse => ({ uid: warehouse.uid, name: warehouse.name })),
            search: true,
            trackBy: 'name',
            native: false,
            canClear: true,
            canDeselect: true,
            inputType: 'search',
            autocomplete: 'off',
            labelProp: 'name',
            valueProp: 'uid',
            object: true,
            closeOnSelect: false,
            default: state.inventory_configuration.warehouses || [],
          },
          ...(auth_store.check_split('group_stocks_by_warehouse') && { group_by_warehouses: {
            type: 'toggle',
            native: false,
            label: $t('Group by warehouse'),
            description: $t('Breakdown scope and quantities by warehouses'),
            default: state.inventory_configuration.group_by_warehouses || false,
          } }),
        }),
      };
    },
    inventory_schema_2: (state) => {
      if (!state.data_set)
        return {};

      return {
        ...(state.inventory_configuration.type === 'material_tracking' && state.material_tracking_schema),
        ...(state.inventory_configuration.type === 'stock_tracking' && state.stock_tracking_schema),
        ...(state.inventory_configuration.type === 'transactions_pivot' && state.transactions_pivot_schema),
        module: {
          type: 'hidden',
          default: 'inventory',
        },
      };
    },
    get_inventory_schema: (state) => {
      return {
        ...state.inventory_schema_1,
        ...state.inventory_schema_2,
      };
    },
  },
  actions: {
    async get_items_with_serial_number_tracking_enabled() {
      if (this.items_with_serial_number_tracking_enabled?.length)
        return;
      const { data } = await this.$services.inventory_items.getAll({
        query: {
          limit: Number.MAX_VALUE,
        },
      });

      this.items_with_serial_number_tracking_enabled = data.items.filter(item => item.is_serial_number).map(item => ({ name: item.name, uid: item.uid }));
    },
    update_inventory_configuration(data) {
      this.inventory_configuration = { ...data };
    },
    update_relative_date_filters(filters) {
      const filters_payload = { ...filters.filters };
      if (filters?.report_filters?.length) {
        filters.report_filters.forEach((f) => {
          if (isDateIntervalOperator(f.operator)) {
            const [first_day_date, last_day_date] = getDateInterval(f.operator);

            filters_payload[`${f.filter_type}_start`] = first_day_date.toISOString();
            filters_payload[`${f.filter_type}_end`] = last_day_date.toISOString();
          }
        });
      }

      return {
        filters: filters_payload,
        report_filters: filters.report_filters,
      };
    },
    set_data() {
      const dashboard_store = useDashboardStore();
      const widget_configuration = dashboard_store.widget_configuration;

      if (
        !Object.keys(widget_configuration || {}).length
      ) {
        this.data_set = true;
        this.inventory_configuration = {
          ...this.inventory_configuration,
          asset_id: dashboard_store.widget_asset,
        };
        return;
      }

      this.inventory_configuration = { ...widget_configuration };

      this.data_set = true;
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDashboardInventoryStore, import.meta.hot));
