<script setup>
import { storeToRefs } from 'pinia';
import { useCommonStore } from '~/common/stores/common.store.js';
import { sortData } from '~/common/utils/common.utils';
import { useDashboardCustomStore } from '~/dashboard/store/dashboard-custom.store.js';
import { useDashboardFormsStore } from '~/dashboard/store/dashboard-forms.store.js';
import { useDashboardScheduleStore } from '~/dashboard/store/dashboard-schedule.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

const dashboard_store = useDashboardStore();
const dashboard_forms_store = useDashboardFormsStore();
const dashboard_custom_store = useDashboardCustomStore();
const dashboard_schedule_store = useDashboardScheduleStore();
const common_store = useCommonStore();

const {
  widget_asset,
  widget_configuration,
} = storeToRefs(dashboard_store);

const assets_options = computed(() => sortData(common_store?.assets, 'name', 'asc'));

const {
  set_widget_asset,
} = dashboard_store;

const form_data = ref({
  asset: null,
  asset_list: [],
});

const org_level_asset_type = computed(() => {
  if (
    ['tasks', 'forms'].includes(widget_configuration.value?.module)
    && dashboard_forms_store.forms_configuration?.type !== 'single_form_view'
  ) {
    return 'multiple_assets';
  }
  else if (
    (widget_configuration.value?.module === 'custom'
      && dashboard_custom_store.custom_configuration?.type !== 'container_views') || dashboard_schedule_store.schedule_configuration?.type === 'projects_list'
  ) {
    return 'no_asset';
  }
  else {
    return 'single_asset';
  }
});

watch(org_level_asset_type, (new_val) => {
  if (new_val === 'multiple_assets') {
    const assets = form_data.value.asset_list || [];
    form_data.value.asset_list = assets;
    set_widget_asset(assets);
  }
  else if (new_val === 'single_asset') {
    const asset = form_data.value.asset || assets_options.value?.[0]?.uid;
    form_data.value.asset = asset;
    set_widget_asset(asset);
  }
  else if (new_val === 'no_asset') {
    set_widget_asset(null);
  }
}, { immediate: false });

function onFormMounted(el$) {
  if (widget_asset.value && typeof widget_asset.value === 'object')
    el$.update({ asset_list: widget_asset.value || [] });
  else el$.update({ asset: widget_asset.value || assets_options.value?.[0]?.uid });
}
</script>

<template>
  <Vueform
    v-model="form_data"
    sync
    class="mb-2"
    :columns="{
      default: {
        container: 12,
        label: 4,
        wrapper: 12,
      },
      sm: {
        label: 4,
      },
      md: {
        label: 4,
      },
      lg: {
        label: 4,
      },
    }"
    size="sm"
    @mounted="onFormMounted"
  >
    <SelectElement
      v-show="org_level_asset_type === 'single_asset'"
      name="asset"
      :label="$t('Asset')"
      input-type="search"
      autocomplete="off"
      value-prop="uid"
      label-prop="name"
      track-by="name"
      :items="assets_options"
      :search="true"
      :native="false"
      :can-clear="false"
      :can-deselect="false"
      @change="set_widget_asset"
    />
    <TagsElement
      v-show="org_level_asset_type === 'multiple_assets'"
      name="asset_list"
      :label="$t('Assets')"
      input-type="search"
      autocomplete="off"
      value-prop="uid"
      label-prop="name"
      track-by="name"
      :items="assets_options"
      :search="true"
      :native="false"
      :can-clear="true"
      :can-deselect="false"
      @change="set_widget_asset"
    />
  </Vueform>
</template>
