<script setup>
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { sleep } from '~/common/utils/common.utils';
import { useDashboardScheduleStore } from '~/dashboard/store/dashboard-schedule.store.js';

const emit = defineEmits(['update']);

const dashboard_schedule_store = useDashboardScheduleStore();

const {
  schedule_configuration,
} = storeToRefs(dashboard_schedule_store);

const AS_OF_OPTIONS = [
  ['today', 'Today'],
  ['exact_date', 'Exact date'],
  ['custom', 'Custom'],
].map((item) => {
  return {
    value: item[0],
    label: item[1],
  };
});

const CUSTOM_TYPES_OPTIONS = [
  ['days', 'days before'],
  ['weeks', 'weeks before'],
  ['months', 'months before'],
].map((item) => {
  return {
    value: item[0],
    label: item[1],
  };
});
const form$ = ref(null);
const init = ref(true);

function emitAsOfUpdates() {
  if (init.value)
    return;
  const form_data = form$.value.data;
  const type = form_data?.as_of?.type;
  switch (type) {
    case 'today': {
      const date = dayjs().format('YYYY-MM-DD');
      emit('update', { as_of_meta: { type, date }, as_of: date });
      break;
    }
    case 'exact_date':
      if (form_data?.as_of?.date) {
        const date = dayjs(form_data.as_of.date).format('YYYY-MM-DD');
        emit('update', { as_of_meta: { type, date }, as_of: date });
      }
      break;
    case 'custom':
      if (form_data?.as_of?.custom?.custom_value >= 1) {
        const date = dayjs().subtract(form_data.as_of.custom.custom_value, form_data.as_of.custom.custom_type).format('YYYY-MM-DD');
        emit('update', { as_of_meta: { type, ...form_data.as_of.custom, date }, as_of: date });
      }
      break;
  }
}
onMounted(async () => {
  const type = schedule_configuration.value?.as_of_meta?.type || 'today';
  form$.value.el$('as_of.type')?.load(type);

  await sleep(100);

  if (type === 'exact_date') {
    form$.value.el$('as_of.date')?.load(schedule_configuration.value?.as_of);
  }
  else if (type === 'custom') {
    form$.value.el$('as_of.custom.custom_value')?.load(schedule_configuration.value?.as_of_meta?.custom_value || 1);
    form$.value.el$('as_of.custom.custom_type')?.load(schedule_configuration.value?.as_of_meta?.custom_type || 'days');
  }
  init.value = false;
  emitAsOfUpdates();
});
</script>

<template>
  <Vueform
    ref="form$"
    size="sm"
    :display-errors="false"
    :columns="{
      default: { container: 12, label: 4, wrapper: 12 },
      sm: { container: 12, label: 4, wrapper: 12 },
      md: { container: 12, label: 4, wrapper: 12 },
    }"
    @change="emitAsOfUpdates"
  >
    <ObjectElement name="as_of" :label="$t('As of')">
      <SelectElement
        name="type"
        :items="AS_OF_OPTIONS"
        :native="false"
        :can-clear="false"
        :search="true"
        :can-deselect="false"
        :default="AS_OF_OPTIONS[0].value"
        input-type="search"
        autocomplete="off"
      />
      <DateTimeElement
        v-if="form$?.data?.as_of?.type === 'exact_date'"
        name="date"
        rules="required"
        :options="{
          clearable: false,
          format: 'dd MMMM yyyy',
          maxDate: new Date(),
        }"
      />
      <ObjectElement
        v-if="form$?.data?.as_of?.type === 'custom'"
        name="custom"
        :add-classes="{
          ElementLayout: {
            container: 'w-full',
          },
        }"
        :columns="{
          default: { wrapper: 12 },
          sm: { wrapper: 12 },
          md: { wrapper: 12 },
        }"
      >
        <TextElement
          name="custom_value"
          input-type="number"
          force-numbers
          rules="required|min:1|numeric"
          :default="1"
          :columns="{
            default: { container: 6, wrapper: 12 },
            sm: { container: 6, wrapper: 12 },
            md: { container: 6, wrapper: 12 },
          }"
        />
        <SelectElement
          name="custom_type"
          :items="CUSTOM_TYPES_OPTIONS"
          :native="false"
          :can-clear="false"
          :search="true"
          :can-deselect="false"
          default="days"
          input-type="search"
          autocomplete="off"
          :columns="{
            default: { container: 6, wrapper: 12 },
            sm: { container: 6, wrapper: 12 },
            md: { container: 6, wrapper: 12 },
          }"
        />
      </ObjectElement>
    </ObjectElement>
  </Vueform>
</template>
