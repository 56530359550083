import dayjs from 'dayjs';
import { keyBy } from 'lodash-es';
import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

const { getFormattedDate } = useFamConstants();

const status_options = [
  [1, 'Pending'],
  [2, 'In-progress'],
  [3, 'Resolved'],
  [4, 'Closed'],
  [5, 'Rejected'],
].map((item) => {
  return {
    uid: item[0],
    name: item[1],
  };
});

const priority_options = [
  [1, 'Critical'],
  [2, 'High'],
  [3, 'Medium'],
  [4, 'Low'],
  [5, 'Not set'],
].map((item) => {
  return {
    uid: item[0],
    name: item[1],
  };
});

export const FILTER_FIELDS = [
  {
    uid: 'status',
    name: 'Status',
    data_type: 'single_select',
    is_static: true,
    option_type: null,
    operators: ['isAnyOf', 'isNot'],
    options: status_options,
    type: 'dropdown',
  },
  {
    uid: 'priority',
    name: 'Priority',
    data_type: 'single_select',
    is_static: true,
    option_type: null,
    operators: ['isAnyOf', 'isNot'],
    options: priority_options,
    type: 'dropdown',
  },
  {
    uid: 'assignees',
    name: 'Assignees',
    data_type: 'multi_select',
    is_static: true,
    option_type: 'members',
    operators: [
      'containsAnyOf',
      'doesNotContain',
    ],
    options: [],
    type: 'members',
  },
  {
    uid: 'owner',
    name: 'Owner',
    data_type: 'single_select',
    is_static: true,
    option_type: 'members',
    operators: ['isAnyOf', 'isNot'],
    options: [],
    type: 'member',
  },
  {
    uid: 'watchers',
    name: 'Watchers',
    data_type: 'multi_select',
    is_static: true,
    option_type: 'members',
    operators: [
      'containsAnyOf',
      'doesNotContain',
    ],
    options: [],
    type: 'members',
  },
  {
    uid: 'start_date',
    name: 'Start Date',
    data_type: 'date',
    is_static: true,
    option_type: null,
    operators: [
      'isEqualTo',
      'isAfter',
      'isBefore',
      'between',
    ],
    options: [],
    type: 'date',
  },
  {
    uid: 'due_date',
    name: 'Due Date',
    data_type: 'date',
    is_static: true,
    option_type: null,
    operators: [
      'isEqualTo',
      'isAfter',
      'isBefore',
      'between',
    ],
    options: [],
    type: 'date',
  },
  {
    uid: 'created_at',
    name: 'Created At',
    data_type: 'date',
    is_static: true,
    option_type: null,
    operators: [
      'isEqualTo',
      'isAfter',
      'isBefore',
      'between',
    ],
    options: [],
    type: 'date',
  },
  {
    uid: 'category',
    name: 'Category',
    data_type: 'single_select',
    is_static: true,
    option_type: 'categories',
    operators: ['isAnyOf', 'isNot'],
    options: [],
    type: 'dropdown',
  },
  {
    uid: 'tags',
    name: 'Tags',
    data_type: 'multi_select',
    is_static: true,
    option_type: 'tags',
    operators: [
      'containsAnyOf',
      'doesNotContain',
    ],
    options: [],
    type: 'dropdown',
  },
  {
    uid: 'checklist_percent',
    name: 'Checklist Percent',
    data_type: 'number',
    type: 'number',
    is_static: true,
    operators: [
      'isLessThan',
      'isGreaterThan',
      'isBetween',
    ],
  },
];

function dateRuleToFilter(rule, report_filter) {
  const filter = {};
  report_filter.value = getFormattedDate(rule.operator_option) || rule.value;
  if (Array.isArray(report_filter.value) && report_filter.value.length === 2) {
    filter[`${rule.field}_start`] = dayjs(report_filter.value[0]).toISOString();
    filter[`${rule.field}_end`] = dayjs(report_filter.value[1]).toISOString();
    report_filter.type = 'between';
  }
  else if (rule.operator === 'isBefore') {
    filter[`${rule.field}_lt`] = dayjs(report_filter.value).toISOString();
  }
  else if (rule.operator === 'isAfter') {
    filter[`${rule.field}_gt`] = dayjs(report_filter.value).toISOString();
  }
  else if (rule.operator === 'isEqualTo' && dayjs(rule.value).isValid()) {
    report_filter.value = [dayjs(rule.value).startOf('day'), dayjs(rule.value).endOf('day')];
    report_filter.type = 'between';
    filter[`${rule.field}_start`] = report_filter.value[0].toISOString();
    filter[`${rule.field}_end`] = report_filter.value[1].toISOString();
  }
  return { filter, report_filter };
}

// Formats a list of rules from fam-tab-form-rules component into filters and report filters.
export function ruleToFilterFormatter(rules) {
  let filters = {};
  const report_filters = [];

  const operator_map = {
    isAnyOf: 'is',
    containsAnyOf: 'is',
    isNot: 'is_not',
    doesNotContain: 'is_not',
    isEqualTo: 'is',
    isAfter: 'later',
    isBefore: 'earlier',
    between: 'between',
    isLessThan: 'less than',
    isGreaterThan: 'greater than',
    isBetween: 'between',
  };
  rules?.forEach((rule) => {
    let filter = {};
    let report_filter = {
      name: rule.field,
      type: operator_map[rule.operator],
    };

    switch (rule.field) {
      case 'start_date':
      case 'due_date':
      case 'created_at':
        ({ filter, report_filter } = dateRuleToFilter(rule, report_filter));
        break;
      case 'checklist_percent':
        if (rule.operator === 'isBetween') {
          report_filter.value = [Number(rule.value[0]), Number(rule.value[1])];
          filter.progress_start = report_filter.value[0] / 100;
          filter.progress_end = report_filter.value[1] / 100;
        }
        else {
          report_filter.value = Number(rule.value);
          if (rule.operator === 'isLessThan')
            filter.progress_end = report_filter.value / 100;
          else
            filter.progress_start = report_filter.value / 100;
        }
        break;
      default:
        report_filter.value = rule.value;
        if (rule.operator === 'isNot' || rule.operator === 'doesNotContain')
          filter[`${rule.field}_not`] = report_filter.value;
        else
          filter[rule.field] = report_filter.value;
    }

    report_filters.push(report_filter);
    filters = { ...filters, ...filter };
  });
  return { filters, report_filters };
}

function dateFilterToRule(filter) {
  const filter_rule = {
    field: filter.name,
    operator: filter.type,
    value: filter.value,
  };

  filter_rule.operator_option = filter.type;
  filter_rule.operator = 'isEqualTo';
  if (filter.type === 'is') {
    filter_rule.operator = 'isEqualTo';
    filter_rule.operator_option = 'exact_date';
  }
  else if (filter.type === 'later') {
    filter_rule.operator = 'isAfter';
    filter_rule.operator_option = 'exact_date';
  }
  else if (filter.type === 'earlier') {
    filter_rule.operator = 'isBefore';
    filter_rule.operator_option = 'exact_date';
  }
  else if (filter.type === 'between') {
    filter_rule.operator = 'between';
    filter_rule.operator_option = 'custom_range';
  }
  else if (filter.type === 'this_year') {
    filter_rule.operator = 'isEqualTo';
    filter_rule.operator_option = 'year_to_date';
  }
  return filter_rule;
}

// Formats a list of report filters into rules supported by fam-tab-form-rules component.
export function filterToRuleFormatter(filters) {
  const filter_rules = filters.map((filter) => {
    const rule_object = keyBy(FILTER_FIELDS, 'uid')[filter.name];
    if (!rule_object)
      return {};

    let filter_rule = {
      field: filter.name,
      operator: filter.type,
      value: filter.value,
    };
    switch (rule_object.data_type) {
      case 'date':
        filter_rule = dateFilterToRule(filter);
        break;
      case 'number':
        if (filter.type === 'between')
          filter_rule.operator = 'isBetween';
        else if (filter.type === 'greater than' || filter.type === 'greater than or equal to')
          filter_rule.operator = 'isGreaterThan';
        else
          filter_rule.operator = 'isLessThan';
        break;
      case 'single_select':
        if (filter.type === 'is')
          filter_rule.operator = 'isAnyOf';
        else
          filter_rule.operator = 'isNot';
        break;
      case 'multi_select':
        if (filter.type === 'is')
          filter_rule.operator = 'containsAnyOf';
        else
          filter_rule.operator = 'doesNotContain';
        break;
    }
    return filter_rule;
  });
  return filter_rules.filter(f => f.field);
}
